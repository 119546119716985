import React,{useEffect,useState} from 'react'
import { Row, Col, Container } from "react-bootstrap"
import "./AdminPanel.css"
import Sidebar from "../Sidebar/Sidebar"
import { FaHome, FaCity } from "react-icons/fa";
import { RiUser3Fill } from 'react-icons/ri';
import { SiGooglemaps } from "react-icons/si";
import { useSelector } from 'react-redux';

const AdminPanel = () => {
  const [city,setCity] = useState(0);
  const [area,setArea] = useState(0);
  const [prona,setProna] = useState(0);
  const token = localStorage.getItem('token');

  useEffect(() => {

    const fetchCities = async () => {
      
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
     
      if (data.success === false) {
        return;
      }
      setCity(data.length);
    };
    const fetchAreas = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/areas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setArea(data.length);
    };
    const fetchData = async () => {
   
        let endpoint;
        endpoint = process.env.REACT_APP_BASE_URL+'/user/listings';
        const res = await fetch(endpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        const data = await res.json();
        if (data.success === false) {
          return;
        }
  
        setProna(data);
      } 
    fetchCities();
    fetchAreas();
    fetchData();
  }, []);
  const {currentUser} = useSelector((state)=>  state.user);
  return (
    <div className="ngjyra">
 <Sidebar/>
    <Container className="stats-admin">
      <h2 className='welcome-user'>Welcome {currentUser.username}</h2>
   
      <Row className="tre-div">
        <Col md={12} lg={6}><div className="kuadrati-1"><p className="numri-res">+{prona.length} Prona</p>< FaHome className="icon-adminn" /></div></Col>
        <Col md={12} lg={6}><div className="kuadrati-2"><p className="numri-res">+{city} Qytete</p>< FaCity className="icon-admin" /></div></Col>
        <Col md={12} lg={6}><div className="kuadrati-3"><p className="numri-res">+{area} Zona </p>< SiGooglemaps className="icon-admin" /></div></Col>
        <Col md={12} lg={6}><div className="kuadrati-4"><p className="numri-res">+150 Kliente</p>< RiUser3Fill className="icon-admin" /></div></Col>
      </Row>

    </Container>

  </div>
  )
}

export default AdminPanel
