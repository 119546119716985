import React from "react";
import { Col, Card } from "react-bootstrap";
import { BiSolidBath } from "react-icons/bi";
import { HiSquare2Stack } from "react-icons/hi2";
import { SiGooglemaps } from "react-icons/si";
import { IoPricetag } from "react-icons/io5";
import {FaBed} from "react-icons/fa";
const ListingItem = ({ item }) => {
  return (
    
      <Col lg={4} md={4}>
        <div className="prona-type">{item.type}</div>
        <Card className="listing-item">
          <div className="image-part">
            <a href={`/listing/${item._id}`}>
              <Card.Img
                variant="top"
                src={item.imageUrls[0]}
                className="portfolio-image"
              />
            </a>
          </div>

          <Card.Body>
            <p className="property-id">{item.name}</p>
            <Card.Title>{item.shortdescription}</Card.Title>
            <Card.Text>
              <div>
                <span className="prona-icons">
                  <BiSolidBath />
                  {item.bathrooms}
                </span>
                <span className="prona-icons">
                  <FaBed />
                  {item.bedrooms}
                </span>
                <span className="prona-icons">
                  <HiSquare2Stack />
                  {item.surface}
                  <span>
                    m<sup>2</sup>
                  </span>
                </span>
              </div>
              <hr />
              <span className="prona-icons">
                <SiGooglemaps />
                {item.city}, {item.area}
              </span>
              <span className="prona-icons price">
                <IoPricetag />
                {(item.type === "qira" || item.type === "u dha me qera") ? (
                       item.coin === "Euro" ? `${item.advertisedPrice} €/muaj` : `${item.advertisedPrice} L/muaj`
                      ):
                      (
                        item.coin === "Euro" ? `${item.advertisedPrice} €` : `${item.advertisedPrice} L`
                      )
                    }
              </span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    
  );
};

export default ListingItem;
