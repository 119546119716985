import React, { useState, useEffect } from "react";
import "./Filter.css";
import Sidebar from "../Sidebar/Sidebar";
import { FaPlusCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Filter = () => {
  const [city, setCity] = useState(false);
  const [area, setArea] = useState(false);
  const [id, setId] = useState(false);
  const [tipology,setTipology] = useState(false);

  const [existingCity, setExistingCity] = useState({ name: "" });
  const [existingArea, setExistingArea] = useState({ name: "" });
  const [existingId, setExistingId] = useState({ name: "" });
  const [existingTipology, setExistingTipology] = useState({ name: "" });

  const [error, setError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [tipologyError, setTipologyError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [newCity, setNewCity] = useState({ name: "" });
  const [newArea, setnewArea] = useState({ name: "" });
  const [newId, setnewId] = useState({ name: "" });
  const [newTipology, setnewTipology] = useState({ name: "" });
  const [files, setFiles] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCities = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/user/cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();

      if (data.success === false) {
        return;
      }
      setExistingCity(data);
    };
    const fetchAreas = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/user/areas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setExistingArea(data);
    };
    const fetchIds = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/user/ids", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setExistingId(data);
    };
    const fetchTipologies = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/user/tipologies", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setExistingTipology(data);
    }
    fetchCities();
    fetchAreas();
    fetchIds();
    fetchTipologies();
  }, []);

  const handleCity = () => {
    setCity(true);
    setArea(false);
    setId(false);
    setTipology(false);
  };
  const handleArea = () => {
    setCity(false);
    setArea(true);
    setId(false);
    setTipology(false);
  };
  const handleId = () => {
    setCity(false);
    setArea(false);
    setId(true);
    setTipology(false);
  };
  const handleTipology = ()=>{
    setCity(false);
    setArea(false);
    setId(false);
    setTipology(true);
  }
  const handleAddCity = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      setLoading(true);
      // Check if the city already exists in the local state
      const existingCityNames = existingCity.map(item => item.name.toLowerCase().trim());
      const newCityName = newCity.name.toLowerCase().trim();
      
      if (existingCityNames.includes(newCityName)) {
        setCityError("Ky qytet ekziston");
        setLoading(false);
        return;
      }
      const res = await fetch(
        process.env.REACT_APP_BASE_URL + "/addCity/city",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            ...newCity,
            userRef: currentUser._id,
          }),
        }
      );
      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      }
      navigate(`/admin`);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleAddArea = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      setLoading(true);
      // Check if the area already exists in the local state
      const existingAreaNames = existingArea.map(item => item.name.toLowerCase().trim());
      const newAreaName = newArea.name.toLowerCase().trim();
      
      if (existingAreaNames.includes(newAreaName)) {
        setAreaError("Kjo zone ekziston");
        setLoading(false);
        return;
      }
      const res = await fetch(
        process.env.REACT_APP_BASE_URL + "/addArea/area",
       
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            ...newArea,
            userRef: currentUser._id,
          }),
        }
      );
      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      }
      navigate(`/admin`);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleAddId = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      setLoading(true);
      // Check if the area already exists in the local state
      if (existingId.some((item) => item.name === newId.name)) {
        setIdError("Kjo id ekziston");
        setLoading(false);
        return;
      }
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/addId/id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          ...newId,
          userRef: currentUser._id,
        }),
      });
      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      }
      navigate(`/admin`);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleAddTipology = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      setLoading(true);
      // Check if the area already exists in the local state
      if (existingTipology.some((item) => item.name === newTipology.name)) {
        setTipologyError("Kjo tipologji ekziston");
        setLoading(false);
        return;
      }
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/addTipology/tipology", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          ...newTipology,
          userRef: currentUser._id,
        }),
      });
      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      }
      navigate(`/admin`);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleCityChange = (e) => {
    setNewCity({ name: e.target.value });
  };
  const handleAreaChange = (e) => {
    setnewArea({ name: e.target.value });
  };
  const handleIdChange = (e) => {
    setnewId({ name: e.target.value });
  };
  const handleTipologyChange = (e) => {
    setnewTipology({name: e.target.value});
  }

  return (
    <section>
      <Sidebar />
      <div className="div-button">
        <div className="shto-button">
          <button className="shto_res" onClick={handleCity}>
            {" "}
            Shto nje qytet <FaPlusCircle className="plus_sign" />
          </button>
        </div>
        {city && (
          <div className="city-div">
            <input
              type="text"
              placeholder="Qyteti"
              value={newCity.name}
              onChange={handleCityChange}
              id="newCity"
            />
            <button className="city-button" onClick={handleAddCity}>
              Shto qytetin
            </button>
            <p className="filter-error">{cityError}</p>
          </div>
        )}

        <div className="shto-button">
          <button className="shto_res" onClick={handleArea}>
            {" "}
            Shto nje zone <FaPlusCircle className="plus_sign" />
          </button>
        </div>
        {area && (
          <div className="area-div">
            <input
              type="text"
              placeholder="Zona"
              value={newArea.name}
              onChange={handleAreaChange}
              id="newArea"
            />
            <button className="area-button" onClick={handleAddArea}>
              Shto zonen
            </button>
            <p className="filter-error">{areaError}</p>
          </div>
        )}
        <div className="shto-button">
          <button className="shto_res" onClick={handleId}>
            {" "}
            Shto Id <FaPlusCircle className="plus_sign" />
          </button>
        </div>
        {id && (
          <div className="area-div">
            <input
              type="text"
              placeholder="Id"
              value={newId.name}
              onChange={handleIdChange}
              id="newId"
            />
            <button className="area-button" onClick={handleAddId}>
              Shto Id
            </button>
            <p className="filter-error">{idError}</p>
            {/* <h1>CSV File Upload</h1>
          <input type="file" onChange={handleIdChange} />
          <button onClick={handleFileUpload}  encType="multipart/form-data">Upload</button> */}
          </div>
        )}
        <div className="shto-button">
          <button className="shto_res" onClick={handleTipology}>
            {" "}
            Shto nje tipologji <FaPlusCircle className="plus_sign" />
          </button>
        </div>
        {tipology && (
          <div className="area-div">
            <input
              type="text"
              placeholder="Tipologjia"
              value={tipology.name}
              onChange={handleTipologyChange}
              id="newTipology"
            />
            <button className="area-button" onClick={handleAddTipology}>
              Shto Tipologjine
            </button>
            <p className="filter-error">{tipologyError}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Filter;
