import React from "react";
import "./Logout.css"
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { signOutUserFailure, signOutUserStart, signOutUserSuccess } from "../../redux/user/userSlice";
import {useDispatch} from 'react-redux';


const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSignOut = async () =>{
        try{
            dispatch(signOutUserStart());
            const res = await fetch(process.env.REACT_APP_BASE_URL+'/auth/signout');
            const data = await res.json();
            if(data.success === false){
                dispatch(signOutUserFailure(data.message));
                return;
                
            }
            dispatch(signOutUserSuccess(data));
            window.localStorage.clear();
        }
        catch(error){
            dispatch(signOutUserFailure(error.message));
        }
    }
    return (

        <div>
            <Sidebar/>
        
        <div className="log_out">
            <h3>A jeni te sigurt qe deshironi te dilni?</h3>
            <button className="yes_but"
                onClick={handleSignOut}
            >
                Po
            </button>
            <button className="no_but"
                onClick={() => {
                    navigate(`/admin`);
                }}
            >
                Jo
            </button>
        </div>
        </div>
    );
};


export default Logout;
