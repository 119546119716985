// Seksioni i userave ne panelin e adminit
import { React, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Table, } from 'react-bootstrap';
import "./User.css"
import Sidebar from '../Sidebar/Sidebar';
import { useSelector } from 'react-redux'
import {useDispatch} from 'react-redux';
import { deleteUserFailure, deleteUserStart, deleteUserSuccess } from '../../redux/user/userSlice';




const User = () => {
  const [records, setRecords] = useState([]);
  const {currentUser} = useSelector((state)=>  state.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

// terheqja e userave nga databaza
  useEffect(() => {
    const fetchUsers = async () => {
       
      
        const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/allUsers",{
          method: "GET",
          headers:{
              "Content-Type":"application/json",
              'Authorization': `Bearer ${token}`,
             },
        });
        const data = await res.json();
        
        if (data.success === false) {
          return;
        }
        setRecords(data);
      };
  
      fetchUsers();
  }, []);


// fshirja e userave
  const removeRecord = async (id) => {
    if (window.confirm('A jeni te sigurt qe do ta fshini userin?'))
    try{
      dispatch(deleteUserStart());
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/delete/${id}`,{
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if(data.success === false){
          dispatch(deleteUserFailure(data.message));
          return;
          
      }
      if(id===currentUser.id){
        dispatch(deleteUserSuccess(data));
      }
     
    }catch(error){
      dispatch(deleteUserFailure(error.message));
    }
  };

  return (
    <div>
        <Sidebar/>
      <div className="res-table">
      {records && records.length ?
        <Table striped bordered hover size="sm" className='table'>
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Agent</th>
              <th>Confirmed</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>


           {
              records.map((record) => {
                
                return <tr>
                  <td style={{ width: "25%" }}>{record.email}</td>
                  <td style={{ width: "25%" }}>{record.role}</td>
                  {(record.isAgent === true) ? <td style={{ width: "25%" }}>Po</td> : <td style={{ width: "25%" }}>Jo</td>}
                  {(record.confirmed === true) ? <td style={{ width: "25%" }}>Po</td> : <td style={{ width: "25%" }}>Jo</td>}
                  {(currentUser.role!='admin') &&
                    <td style={{ width: "25%" }}>
                      <div>
                        <p>You can't edit/delete this account</p>

                      </div>
                    </td>
                  }
                  {(currentUser.role === 'admin')&&
                    <td style={{ width: "25%" }}>
                      <div>
                        <Link className="edit_link" to={`/user/update/${record._id}`}>Edit</Link>
                        <button className="delete_btn" onClick={() => removeRecord(record._id)}>Delete</button>

                      </div>
                    </td>
                  }

                </tr>
              })
            }

             

          </tbody>
        </Table>
         : <div>Ka nje problem me marrjen e te dhenave</div>}
      </div>
    </div>
  )

}
export default User;
