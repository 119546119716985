import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "./Card"; // Importo komponentin e kartave
import "./BestProperty.css";
import { Container } from "react-bootstrap";

const BestProperty = () => {
  const [formData, setFormData] = useState([]);
  const [bestpropertydata, setBestPropertyData] = useState([]);

  useEffect(() => {
    const fetchListing = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/alllistings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success === false) {
        console.log(data.message);
        return;
      }
      setFormData(data);
      const filteredData = data.filter(item => item.homepage && item.bestproperty);
      const sortedData = filteredData.sort((a, b) => {
        // Assuming 'createdAt' is the timestamp of the properties
        return new Date(b.createdAt) - new Date(a.createdAt);
        });
      setBestPropertyData(sortedData);
    };

    fetchListing();
  }, []);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    768: { items: 3 },
  };
  return (
    <div>
      <div className="prona-text">
        <h1 className="prona-title">PRONA E ËNDËRRAVE</h1>
        <h2 className="prona-subtitle">Pronat</h2>
      </div>
      <Container className="fluid">
      <AliceCarousel
        responsive={responsive}
        autoPlay
        autoPlayInterval={2000}
        disableDotsControls ={true}
        infinite
        items={bestpropertydata.map(
          (card) =>
              <Card
                card = {card}
                title={card.name}
                shortdescription={card.shortdescription}
                imageUrl={card.imageUrls}
                bathrooms={card.bathrooms}
                bedrooms={card.bedrooms}
                type={card.type}
                surface={card.surface}
                address={card.city}
                price={card.advertisedPrice}
                coin = {card.coin}
                area = {card.area}
              />
        )}
      />
      </Container>
    </div>
  );
};

export default BestProperty;
