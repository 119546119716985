import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "./Prona.css";
import { BiSolidBath } from "react-icons/bi";
import { FaBed } from "react-icons/fa";
import { HiSquare2Stack } from "react-icons/hi2";
import { SiGooglemaps } from "react-icons/si";
import { IoPricetag } from "react-icons/io5";

const Prona = () => {
  const [formData, setFormData] = useState([]);
  useEffect(() => {

    const fetchListing = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/alllistings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      const sortedData = data.sort((a, b) => {
        // Assuming 'createdAt' is the timestamp of the properties
        return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setFormData(sortedData);
    };

    fetchListing();
  }, []);
  const limitedData = formData
  .filter((item) => item.homepage && !item.bestproperty)
  .slice(0, 3);

  return (
    <section id="prona-container">
      <div className="best-property-div">
        <h1>Pronat e listuara</h1>
        <p>Pronat e fundit</p>
      </div>
      
      <Container>
        <Row>
          {limitedData.map((item) => (
            <Col md="4">
              <div className="prona-type">{item.type}</div>
              <Card className="prona-card">
                <div className="image-part">
                <a href={`/listing/${item._id}`}>
                <Card.Img
                  variant="top"
                  src={item.imageUrls[0]}
                  className="portfolio-image"
                />
                </a>
                </div>
                <Card.Body className="prona-card-body">
                  <p className="property-id">{item.name}</p>
                  <Card.Title>{item.shortdescription}</Card.Title>
                  <Card.Text>
                    <div>
                      <span className="prona-icons">
                        <BiSolidBath />
                        {item.bathrooms}
                      </span>
                      <span className="prona-icons">
                        <FaBed />
                        {item.bedrooms}
                      </span>
                      <span className="prona-icons">
                        <HiSquare2Stack />
                        {item.surface}<span>m<sup>2</sup></span>
                      </span>
                    </div>
                    <hr />
                    <span className="prona-icons">
                      <SiGooglemaps />
                      {item.city}, {item.area}
                    </span>
                    <span className="prona-icons price">
                      <IoPricetag/>
                      {(item.type === "qira" || item.type === "u dha me qera") ? (
                       item.coin === "Euro" ? `${item.advertisedPrice} €/muaj` : `${item.advertisedPrice} L/muaj`
                      ):
                      (
                        item.coin === "Euro" ? `${item.advertisedPrice} €` : `${item.advertisedPrice} L`
                      )
                    } </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <a href="/allproperties"><button className="prona-button" >Shiko më shumë</button></a>
      </Container>
    </section>
  );
};

export default Prona;
