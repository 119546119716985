import React from 'react'
import "./Whatsapp.css"
import { FaWhatsappSquare } from "react-icons/fa";
const Whatsapp = ({ propertyUrl}) => {
    const whatsappMessage = `Jam i interesuar per kete prone: ${propertyUrl}`;
  return (
    
    <div>
    <a href={`https://wa.me/355697076668?text=${encodeURIComponent(whatsappMessage)}`} target='blank'>
        <span className='wp-icon'><FaWhatsappSquare/></span></a>
    </div>
  )
}

export default Whatsapp