import React from "react";
import { BiSolidBath } from "react-icons/bi";
import { FaBed } from "react-icons/fa";
import { HiSquare2Stack } from "react-icons/hi2";
import { SiGooglemaps } from "react-icons/si";
import { IoPricetag } from "react-icons/io5";
import "./Card.css"
const Card = ({ card, title, shortdescription, imageUrl,bathrooms, bedrooms,type, surface,address, price, coin, area }) => {

  return (
    <div className="card">
      <div className="prona-type2">{type}</div>
      <div className="image-part">
      <a href={`/listing/${card._id}`}>
      <img src={imageUrl[0]} alt={title} className="card-image" />
      </a>
      </div>
      <p className="best-property-id">{title}</p>
      <h5 className="best-property-description">{shortdescription}</h5>
      <div>
        <span className="prona-icons">
          <BiSolidBath />
          {bathrooms}
        </span>
        <span className="prona-icons">
          <FaBed />
          {bedrooms}
        </span>
        <span className="prona-icons">
          <HiSquare2Stack />
          {surface}<span>m<sup>2</sup></span>
        </span>
      </div>
      <hr />
      <div className="footer-property-icons mb-3">
      <span className="prona-icons">
        <SiGooglemaps />
        {address}, {area}
      </span> 
      <span className="prona-icons price">
        <IoPricetag/>
        {(type === "qira" || type === "u dha me qera" )? (
            coin === "Euro" ? `${price} €/muaj` : `${price} L/muaj`
          ):
          (
            coin === "Euro" ? `${price} €` : `${price} L`
          )
                    }</span>
        </div>
    </div>
  );
};

export default Card;
