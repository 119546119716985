import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { FaInstagram,FaFacebook,FaTiktok } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { MdContactPhone } from "react-icons/md";
import { PiCaretRightBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { SiPlanet } from "react-icons/si";

const Footer = () => {
  return (
    <Container fluid id="footer">
      <Container className="footer-container">
        <Row>
          <Col md="4">
            <h3 className="footer-title">Shërbimet</h3>

            <ul className="list-footer">
              <li>
                <PiCaretRightBold />
                <span >Ndërmjetësim në shit-blerje</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span >Ndërmjetësim në qeradhënie</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span >Konsulencë imobiliare</span>
              </li>
              <li>
                <PiCaretRightBold />
                <span > Konsulencë juridike</span>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h3 className="footer-title">Sections</h3>
            <div className="working-hours">
            <ul className="list-footer">
              <li>
                <PiCaretRightBold />
                <Link><span>Kreu</span></Link>
              </li>
              <li>
                <PiCaretRightBold />
                <Link><span>Rreth Nesh</span></Link>
              </li>
              <li>
                <PiCaretRightBold />
                <Link><span>Prona</span></Link>
              </li>
              <li>
                <PiCaretRightBold />
                <Link><span>Shërbimet</span></Link>
              </li>
            </ul>
            </div>
          </Col>
          <Col md={4}>
            <h3 className="footer-title">Kontakte</h3>
            <div className="footer-about">
                <p className="footer-about-description">
               IDEAL HOME REAL ESTATE - Prona juaj, Angazhimi ynë
                </p>
                <p>
                    <MdContactPhone/><span>+355 69 70 76 668</span>
                </p>
                <p>
                    <MdContactPhone/><span>+355 69 38 37 358</span>
                </p>
                <p>
                    <AiOutlineMail/><span>realestateidealhome@gmail.com</span>
                </p>
                <span className="instagram"><a href="https://www.instagram.com/ideal.homerealestate/"><FaInstagram className="instagram-icon"></FaInstagram></a></span>
                <span className="facebook"><a href="https://m.facebook.com/people/IDEAL-HOME-Real-Estate/100087684639199/"><FaFacebook className="facebook-icon"></FaFacebook></a></span>
                <span className="tiktok"><a href="https://www.tiktok.com/@idealhomerealestat?_t=8irXlFeikNy&_r=1 "><FaTiktok className="tiktok-icon"></FaTiktok></a></span>
            </div>
          </Col>
          <p className="copyright">Te drejtat ekskluzive IDEAL HOME REAL ESTATE</p>
        </Row>
      </Container>
    </Container>
  );
};
export default Footer;
