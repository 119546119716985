import React, { useState } from 'react'
import "./Signup.css"
import { Link,useNavigate } from 'react-router-dom'


const Signup = () => {
    const [formData,setFormData] = useState({});
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const [role,setRole] = useState("");
    const navigate = useNavigate();
    const [usernameError, setUsernameError] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');

    const handleStateChange = (e) =>{
        if(e.target.id === "username"){
            const usernameRegex = /^[a-zA-Z\s]+$/;
            if (usernameRegex.test(e.target.value)) {
                const names = e.target.value.trim().split(" ");
                if (names.length === 2) {
                    // If there are exactly two words (assuming first name and last name), proceed
                    const firstNameInitial = names[0].charAt(0).toUpperCase();
                    const lastNameInitial = names[1].charAt(0).toUpperCase();
                    const loggedInUserPrefix = firstNameInitial + names[0].slice(1) + " " + lastNameInitial + names[1].slice(1);
                    setUsernameError(false);
                    setUsernameErrorMessage('');
    
                    // Update formData including the username
                    setFormData({
                        ...formData,
                        username: loggedInUserPrefix
                    });
                } else {
                    setUsernameError(true);
                    setUsernameErrorMessage('Duhet te shkruani emrin dhe mbiemrin');
                }
            }
            else{
                setUsernameError(true);
                setUsernameErrorMessage('Username duhet te permbaje vetem shkronja dhe hapesire.');
            }
        }
        else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            })
        }
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        try {
            setLoading(true);
            const updatedFormData = {
                ...formData,
                confirmed: false, // Set 'confirmed' to 'false'
                agentUrls:[]
            };
        const res = await fetch (process.env.REACT_APP_BASE_URL+"/auth/signup",{
            method:'POST',
            headers:{
             'Content-Type':'application/json'
            },
            body: JSON.stringify({
                ...updatedFormData,
                role: role // Include the role property in the request payload
            })

        })
        const data = await res.json();
        if(data.success === false){
            setError(data.message);
            setLoading(false);
            return;
        }
        setLoading(false);
        setError(null)
        navigate('/signin');
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
        
    }
  return (

   <div className='center-container'>
    <div className='signup-container'>
        <h4 className='signup-title'>Sign up</h4>
        <form className='signup-form' onSubmit={handleSubmit}>
        <label>Username</label>
        <input type='text' id = "username" onChange={handleStateChange} placeholder='Emri Mbiemri'/>
        {usernameError && <div className="error-username">{usernameErrorMessage}</div>}
        <label>Email</label>
        <input type='email' id = "email" onChange={handleStateChange}/>
        <label>Password</label>
        <input type="password" id="password" onChange={handleStateChange}/>
        <label>Tel</label>
        <input type="text" id="tel" placeholder="+prefix XX XX XX XXX" required onChange={handleStateChange}/>
        <label>Role</label>
        <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className='select-role'
        >
            <option value="">Zgjidhni rolin</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
        </select>
        <button className='signup-button' disabled={loading}> 
            {loading ? 'Loading...':'SUBMIT'} 
        </button>
        </form>
        <div>
            <p>Have an account?</p>
            <Link to={"/signin"}>
                <span className='signin'>Sign in</span>
            </Link>
        </div>
        {error && <p className='errorMessage'>{error}</p>}
    </div>
   </div>
  )
}

export default Signup