import React,{useEffect,useState} from 'react'
import Sidebar from "../Sidebar/Sidebar";
import "./ListaPronave.css"
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const ListaPronave = () => {

    const[formData,setFormData] = useState([]);
    const[reloadData,setReloadData] = useState(false);
    const {currentUser} = useSelector((state)=>  state.user);
    const [showListingError, setShowListingError] = useState(false);
    const token = localStorage.getItem('token');
   

    useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = `${process.env.REACT_APP_BASE_URL}/user/listings`;
        
          const res = await fetch(endpoint, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
    
          const data = await res.json();
          
          if (data.success === false) {
            setShowListingError(true);
            return;
          }
    
          const filteredData = data.filter(item => item.type === "qira");
          setFormData(filteredData);
        } catch (error) {
          setShowListingError(true);
        }
      };
    
      fetchData();
    }, [reloadData]);

    const handleListingDelete = async (listingId)=>{
      if (window.confirm('A jeni te sigurt qe do ta fshini pronen?'))
     try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/listing/delete/${listingId}`,{
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        }
      })
      const data = await res.json();
      if(data.success===false){
        console.log(data.message);
        return;
      }
      // Trigger a re-render by updating the state
      setReloadData(!reloadData);
    
     } catch (error) {
      
     }
    }
  return (
    <div>
       <Sidebar/>
       {
        showListingError ? <p>Ka nje problem me shfaqjen e te dhenave</p>:
        formData.length>0 ? 
        <table striped bordered hover size="sm" className='property-table'>
            <thead>
                <th>ID</th>
                <th>Pershkrimi</th>
                <th>Statusi</th>
                <th>Numri i pronarit</th>
                <th>Komisioni</th>
                <th>Cmimi real</th>
                <th>Cmimi i reklamuar</th>
                <th>Personi menaxhues</th>
                <th>Data</th>
                <th>Veprimet</th>
            </thead>
            <tbody>
                {formData.map(item =>{
                    return <tr>
                        <td>{item.name}</td>
                        <td>{item.shortdescription}</td>
                        <td>{item.type}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.commission}%</td>
                        {(item.type === "qira" || item.type === "u dha me qera") ? (
                          item.coin === "Euro" ? <td>{item.price} €/muaj</td>: <td>{item.price} L/muaj</td>
                        ):
                        (
                        item.coin === "Euro" ? <td>{item.price} € </td> : <td>{item.price} L</td>
                        )
                        }
                        {(item.type === "qira" || item.type === "u dha me qera") ? (
                          item.coin === "Euro" ? <td>{item.advertisedPrice} €/muaj</td>: <td>{item.advertisedPrice} L/muaj</td>
                        ):
                        (
                        item.coin === "Euro" ? <td>{item.advertisedPrice} € </td> : <td>{item.advertisedPrice} L</td>
                        )
                        }
                        <td>{item.user}</td>
                        <td>{new Date(item.createdAt).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          })}</td>
                        <td>
                        {currentUser.role === 'admin' && 
                          <div>
                            <Link to={`/listing/update/${item._id}`} className='edit-listing-button'>Edit</Link>
                            <button className="delete-listing-button" onClick={()=>handleListingDelete(item._id)}>Delete</button>
                          </div>
                        }
                        </td>
                        </tr>
                })}
                
                
            </tbody>
        </table>
         :
         <p>Ky user nuk ka asnje prone ne sistem</p>
         }
    </div>
  )
}

export default ListaPronave
