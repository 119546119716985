import React from "react";
import { MdLaptopWindows } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineGift } from "react-icons/ai";
import "./Wallpaper.css";
import { FaHome,FaCity} from "react-icons/fa";

import { Col, Container, Row } from "react-bootstrap";


const Wallpaper = () => {

  return (
    <section id="how-it-work">
      <div >
        <img className="how-it-work-bg"></img>
        <h1 className="how">Ideal Home ofron</h1>
        <p className="how-p" >Biznesi ynë nuk është thjesht shitja e pronave, por ndihma <br/> për klientët tanë për të gjetur zgjedhjen e duhur për ta.</p>

        <div className="card-div">
          <Container>
          <Row>
            <Col lg={4}>
              <div className="cards-how-it-work ">
                <div>
                  <FaHome className="how-it-work-icon"></FaHome>
                </div>
                <h2 className="how-it-work-title">Ndërmjetësim në shit-blerje</h2>
                <p className="how-it-work-description">
                Agjensia do të ofrojë shërbime ndërmjetësimi për shitjen/blerjen duke kërkuar, marketuar, negociuar në tregun e pasurive të paluajtshme për të gjetur një blerës për pronën që shitësi zotëron. Për ofrimin e shërbimeve të ndërmjetësimit, shitësi do t’i paguajë agjensisë 3% të vlerës totale të cmimit të shitjes, ndërsa blerësi do t’i paguajë agjensisë 1% të vlerës totale të cmimit të shitjes.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="cards-how-it-work">
                <div>
                  <FaCity className="how-it-work-icon"></FaCity>
                </div>
                <h2 className="how-it-work-title">Ndërmjetësim në qeradhënie</h2>
                <p className="how-it-work-description">
                Për ofrimin e shërbimeve të ndërmjetësimit, qiramarrësi do t’i paguajë agjensisë ½ (gjysëm) qira mujore për kontrata nga 0-3 vite. Për kontrata mbi 3 vite tarifa e shërbimit të agjensisë është 1 (një) qira mujore, ndërsa qiradhënësi do t’i paguajë agjensisë 1 (një) qira mujore për kontrata nga 0-3 vite. Për kontrata mbi 3 vite tarifa e shërbimit të agjensisë është 2 (dy) qira mujore.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="cards-how-it-work">
                <div>
                  <IoPeopleOutline className="how-it-work-icon"></IoPeopleOutline>
                </div>
                <h2 className="how-it-work-title">Konsulencë imobiliare dhe juridike</h2>
                <p className="how-it-work-description">
                IDEAL HOME ofron konsulencë imobilare për shitje, blerje, qiramarrje, qiradhënie për personat që interesohen të investojnë në prona të patundshme në të gjithë Tiranën dhe jo vetëm dhe konsulencë ligjore duke siguaruar përfaqësimin ligjor të klientëve, në fusha të ndryshme të së drejtës civile, tregtare, dhe administrative, duke i përfaqësuar klientët në të gjitha shkallët e gjykimit në çështje me juridiksion kombëtar.
                </p>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};
export default Wallpaper;
