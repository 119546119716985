import React, { useState, useEffect } from "react";
import "./AllProperties.css";
import Navbar2 from "../Navbar/Navbar2";
import "../Hero/Hero.css";
import { Col, Row, Container, Card } from "react-bootstrap";
import {
  FaHome,
  FaBed,
  FaEuroSign,
  FaSearchengin,
  FaCity,
  FaBath,
} from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { IoFilterOutline, IoPricetag } from "react-icons/io5";
import { HiOutlineArrowsPointingOut } from "react-icons/hi2";
import { MdLastPage } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { GrPrevious, GrNext } from "react-icons/gr";
import Footer from "../Footer/Footer";
import { IoKeySharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ListingItem from "./ListingItem";
import Select from 'react-select'


const AllProperties = () => {
  const [formData, setFormData] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [tipology, setTipology] = useState([]);
  const [showFiltraAvancuar, setShowFiltraAvancuar] = useState(false);
  const [sidebardata, setSidebarData] = useState({
    type: "all",
    siperfaqeMin: "",
    siperfaqeMax: "",
    cmimiMin: "",
    cmimiMax: "",
    tipologji: "",
    zona: [],
    city: "",
    name: "",
    bedroom: "",
    bathroom: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const pathname = location.pathname;
  const token = localStorage.getItem('token');
  const [options, setOptions] = useState([]);


  // Check if the URL starts with "get?" or "allproperties"
  const isGetOrAllProperties = pathname.startsWith("/get") ? true : false;

  useEffect(() => {
    const fetchListing = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/alllistings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      // Sort properties by timestamp or any other identifier in descending order
      const sortedData = data.sort((a, b) => {
        // Assuming 'createdAt' is the timestamp of the properties
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFormData(sortedData);
    };
    const fetchCities = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setCity(data);
    };
    const fetchAreas = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/areas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      const sortedAreas = data.sort((a, b) => a.name.localeCompare(b.name));
      setArea(sortedAreas);
      const options = sortedAreas.map((item) => ({
        key: item.name,
        value: item.name
      }));
      setOptions(options);
    };
    const fetchTipologies = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/tipologies", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setTipology(data);
    };

    fetchListing();
    fetchCities();
    fetchAreas();
    fetchTipologies();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const typeFromUrl = urlParams.get("type");
    const siperfaqeMinFromUrl = urlParams.get("siperfaqeMin");
    const siperfaqeMaxFromUrl = urlParams.get("siperfaqeMax");
    const cmimiMinFromUrl = urlParams.get("cmimiMin");
    const cmimiMaxFromUrl = urlParams.get("cmimiMax");
    const tipologjiFromUrl = urlParams.get("tipologji");
    const zonaFromUrl = urlParams.getAll("zona");
    const cityFromUrl = urlParams.get("city");
    const nameFromUrl = urlParams.get("name");
    const bedroomFromUrl = urlParams.get("bedroom");
    const bathroomFromUrl = urlParams.get("bathroom");

    if (
      typeFromUrl ||
      siperfaqeMinFromUrl ||
      siperfaqeMaxFromUrl ||
      cmimiMinFromUrl ||
      cmimiMaxFromUrl ||
      tipologjiFromUrl ||
      zonaFromUrl.length > 0 || // Check if the array has any values
      cityFromUrl ||
      nameFromUrl ||
      bedroomFromUrl ||
      bathroomFromUrl
    ) {
      setSidebarData({
        type: typeFromUrl || "",
        siperfaqeMin: siperfaqeMinFromUrl || "",
        siperfaqeMax: siperfaqeMaxFromUrl || "",
        cmimiMin: cmimiMinFromUrl || "",
        cmimiMax: cmimiMaxFromUrl || "",
        tipologji: tipologjiFromUrl || "",
        zona: zonaFromUrl.length > 0 ? zonaFromUrl : [], // Set as empty array if no values
        city: cityFromUrl || "",
        name: nameFromUrl || "",
        bedroom: bedroomFromUrl || "",
        bathroom: bathroomFromUrl || "",
      });
    }
    const fetchListings = async () => {
      setLoading(true);
      const searchQuery = urlParams.toString();
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/listing/get?${searchQuery}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      setListings(data);
      setLoading(false);
    };

    fetchListings();
  }, [location.search]);

  const handleChange = (e) => {
    if (
      e.target.id === "all" ||
      e.target.id === "qira" ||
      e.target.id === "shitje"
    ) {
      setSidebarData({ ...sidebardata, type: e.target.id });
    }

    if (e.target.id === "tipologji") {
      setSidebarData((sidebardata) => {
        const updatedData = { ...sidebardata, tipologji: e.target.value };
        return updatedData; // Return the updated state
      });
    }
    // if (e.target.id === "zona") {
    //   setSidebarData({ ...sidebardata, zona: e.target.value });
    // }
    if (e.target.id === "bedroom") {
      setSidebarData({ ...sidebardata, bedroom: e.target.value.toString() });
    }
    if (e.target.id === "bathroom") {
      setSidebarData({ ...sidebardata, bathroom: e.target.value.toString() });
    }
    if (e.target.id === "cmimiMin") {
      setSidebarData({ ...sidebardata, cmimiMin: parseFloat(e.target.value) });
    }
    if (e.target.id === "cmimiMax") {
      setSidebarData({ ...sidebardata, cmimiMax: parseFloat(e.target.value) });
    }
    if (e.target.id === "siperfaqeMin") {
      setSidebarData({
        ...sidebardata,
        siperfaqeMin: parseFloat(e.target.value),
      });
    }
    if (e.target.id === "siperfaqeMax") {
      setSidebarData({
        ...sidebardata,
        siperfaqeMax: parseFloat(e.target.value),
      });
    }
    if (e.target.id === "name") {
      setSidebarData({ ...sidebardata, name: e.target.value });
    }

    if (e.target.id === "city") {
      setSidebarData({ ...sidebardata, city: e.target.value });
    }
  };
  // Handle change for react-select
  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSidebarData({ ...sidebardata, zona: selectedValues });
  };
  // Format the value for react-select
  const selectedValues = sidebardata.zona.map(value => ({
    label: value,
    value: value
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams();
    urlParams.set("type", sidebardata.type);
    urlParams.set("siperfaqeMin", sidebardata.siperfaqeMin);
    urlParams.set("siperfaqeMax", sidebardata.siperfaqeMax);
    urlParams.set("cmimiMin", sidebardata.cmimiMin);
    urlParams.set("cmimiMax", sidebardata.cmimiMax);
    urlParams.set("tipologji", sidebardata.tipologji);
    if (sidebardata.zona) {
      sidebardata.zona.forEach((zona) => {
        urlParams.append("zona", zona);
      });
    }
    urlParams.set("city", sidebardata.city);
    urlParams.set("name", sidebardata.name);
    urlParams.set("bedroom", sidebardata.bedroom);
    urlParams.set("bathroom", sidebardata.bathroom);
    const searchQuery = urlParams.toString();
    navigate(`/get?${searchQuery}`);
  };

  const changeFilterMode = (e) => {
    e.preventDefault();
    setShowFiltraAvancuar(!showFiltraAvancuar);
  };

  const state = location.state;

  //pagination data

  const [firstPage] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  if(!isGetOrAllProperties){
    for (let i = 1; i <= Math.ceil(formData.length / itemsPerPage); i++) {
      pages.push(i);
    }
  }
  else{
    if(state && state.listings.length>0){
      for (let i = 1; i <= Math.ceil(state.listings.length / itemsPerPage); i++) {
        pages.push(i);
      }
    }
    else{
      for (let i = 1; i <= Math.ceil(listings.length / itemsPerPage); i++) {
        pages.push(i);
      }
    }
  }

  const handleClick = (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(Number(event.target.id));

  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems=[];
  if(!isGetOrAllProperties){
    currentItems = formData.slice(indexOfFirstItem, indexOfLastItem);
  }
  else{
    if(state && state.listings.length>0){
      currentItems = state.listings.slice(indexOfFirstItem, indexOfLastItem);
    }
    else{
       currentItems = listings.slice(indexOfFirstItem, indexOfLastItem);
    }
  }
  

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }

  });

  const handleNextbtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handleFirst = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(firstPage);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }


  }
  const handleLast = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const length = Math.ceil(formData.length / itemsPerPage)
    setcurrentPage(length)
    if (length + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }


  const handlePrevbtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  // funksionaliteti per 3 pikat djathtas

  const handlethreepointsnextbtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(maxPageNumberLimit + 1);

    setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  };

  // funksionaliteti per 3  pikat majtas

  const handlethreepointsprevbtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setcurrentPage(minPageNumberLimit - 2);

    setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  };

  // kur ka me shume postime se limiti i caktuar shfaqen 3 pikat majtas dhe djathas

  // shfaqja e 3 pikave djathtas
  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handlethreepointsnextbtn}> &hellip; </li>;
  }

  // shfaqja e 3 pikave majtas
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlethreepointsprevbtn}> &hellip; </li>;
  }
  var arrLength = 0;

  return (
    <section className="all-properties">
      <Navbar2 />
      <Container className="all-results">
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="property-options">
            <div>
              <ul className="property-list">
                <li>
                  {" "}
                  <input
                    type="radio"
                    className="btn-check"
                    name="business_type"
                    value="all"
                    id="all"
                    autoComplete="off"
                    checked={sidebardata.type === "all"}
                    onChange={handleChange}
                  />
                  <label className="property-label" htmlFor="all">
                    Të gjitha
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="btn-check"
                    name="business_type"
                    value="shitje"
                    id="shitje"
                    autoComplete="off"
                    checked={sidebardata.type === "shitje"}
                    onChange={handleChange}
                  />
                  <label className="property-label" htmlFor="shitje">
                    Në shitje
                  </label>
                </li>
                <li>
                  {" "}
                  <input
                    type="radio"
                    className="btn-check"
                    name="business_type"
                    value="qira"
                    id="qira"
                    autoComplete="off"
                    checked={sidebardata.type === "qira"}
                    onChange={handleChange}
                  />
                  <label className="property-label" htmlFor="qira">
                    Me qera
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="property-search">
            <Row className="g-0">
              <Col lg="8">
                <Row className="g-0">
                  <Col lg="6">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaHome></FaHome>
                      </span>
                      <select
                        id="tipologji"
                        value={sidebardata.tipologji}
                        onChange={handleChange}
                      >
                        <option value="">Zgjidhni një tipologji</option>
                        {tipology.map((item) => (
                          <option value={item.name} key={item.name}>
                            {item.name}
                          </option>
                        ))}
                        {/* Shtoni opsionet e tjera të tipologjisë sipas nevojës */}
                      </select>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="property-data">
                      <span className="property-icon">
                        <SiGooglemaps></SiGooglemaps>
                      </span>
                      <Select
                          id="zona"
                          value={selectedValues}
                          onChange={handleSelectChange}
                          isMulti
                          options={options.map(option => ({
                            label: option.key,
                            value: option.value
                          }))}
                          placeholder="Zgjidhni zonën"
                        >
                        </Select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="4">
                <Row className="g-0">
                  <Col md="6">
                    <div className="group-button">
                      <span className="property-icon">
                        <IoFilterOutline></IoFilterOutline>
                      </span>
                      <button
                        onClick={changeFilterMode}
                        className="filter-button"
                      >
                        Filtra
                      </button>
                      <button type="submit" className="form-button">
                        Kërko
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {showFiltraAvancuar && (
              <div className="filtra-avancuar2">
                <hr className="horizontal-line" />
                <Row className="g-0 mb-4">
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaBed></FaBed>
                      </span>
                      <select
                        id="bedroom"
                        value={sidebardata.bedroom}
                        onChange={handleChange}
                      >
                        <option value="">Dhoma gjumi</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3">4</option>
                        <option value="3">5</option>
                        {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                      </select>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaBath></FaBath>
                      </span>
                      <select
                        id="bathroom"
                        value={sidebardata.bathroom}
                        onChange={handleChange}
                      >
                        <option value="">Tualete</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                      </select>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaEuroSign></FaEuroSign>
                      </span>
                      <input
                        name="cmimiMin"
                        id="cmimiMin"
                        placeholder="Cmimi min"
                        value={sidebardata.cmimiMin}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaEuroSign></FaEuroSign>
                      </span>
                      <input
                        name="cmimiMax"
                        id="cmimiMax"
                        placeholder="Cmimi max"
                        value={sidebardata.cmimiMax}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaSearchengin></FaSearchengin>
                      </span>
                      <input
                        name="referenca"
                        id="name"
                        placeholder="ID/Referenca"
                        value={sidebardata.name}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <FaCity></FaCity>
                      </span>
                      <select
                        id="city"
                        value={sidebardata.qyteti}
                        onChange={handleChange}
                      >
                        <option value="">Zgjidhni qytetin</option>
                        {city.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                        {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                      </select>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <HiOutlineArrowsPointingOut></HiOutlineArrowsPointingOut>
                      </span>
                      <input
                        name="siperfaqeMin"
                        id="siperfaqeMin"
                        placeholder="Sipërfaqe Min"
                        value={sidebardata.siperfaqeMin}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="property-data">
                      <span className="property-icon">
                        <HiOutlineArrowsPointingOut></HiOutlineArrowsPointingOut>
                      </span>
                      <input
                        name="siperfaqeMax"
                        id="siperfaqeMax"
                        placeholder="Sipërfaqe Max"
                        value={sidebardata.siperfaqeMax}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </form>
      </Container>
      {!isGetOrAllProperties ? (
        <Container className="all-results">
          <Row className="g-0">
            {currentItems.map(
              (item) =>{
                if(!item.bestproperty){
                  arrLength++;
                  return <ListingItem key={item._id} item={item} />
                }
              }
                
            )}
          </Row>
          {/* Pagination */}

          {arrLength.length !== 0 && (
            <ul className="pageNumber">
              <li>
                <button
                  onClick={handleFirst}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  <MdFirstPage className="double-arrow" />
                </button>
              </li>

              <li>
                <button
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  <GrPrevious />
                </button>
              </li>

              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}

              <li>
                <button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  <GrNext />
                </button>
              </li>

              <li>
                <button
                  onClick={handleLast}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  <MdLastPage className="double-arrow" />
                </button>
              </li>
            </ul>
          )}
        </Container>
      ) : (
        <Container className="all-results">
          {state && state.listings.length > 0 ? (
            <h3>Shfaqur {state.listings.length} rezultate</h3>
          ) : (
            <h3>Shfaqur {listings.length} rezultate</h3>
          )}
          <Row className="g-0">
            {state && state.listings.length > 0
              ? currentItems.map((item) => {
                 arrLength++;
                  return <ListingItem key={item._id} item={item} />
              })
              : currentItems.map((item) => {
                arrLength++;
                return <ListingItem key={item._id} item={item} />
              })}
          </Row>
          {/* Pagination */}

          {arrLength.length !== 0 && (
            <ul className="pageNumber">
              <li>
                <button
                  onClick={handleFirst}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  <MdFirstPage className="double-arrow" />
                </button>
              </li>

              <li>
                <button
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  <GrPrevious />
                </button>
              </li>

              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}

              <li>
                <button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  <GrNext />
                </button>
              </li>

              <li>
                <button
                  onClick={handleLast}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  <MdLastPage className="double-arrow" />
                </button>
              </li>
            </ul>
          )}
        </Container>
      )}
    </section>
  );
};
export default AllProperties;
