import React from 'react';
import { FaWhatsappSquare } from "react-icons/fa";
const WhatsAppShareButton = ({ propertyUrl, text }) => {
  const handleWhatsAppShare = () => {
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(propertyUrl);
    window.open(`https://wa.me/?text=${encodedText}%20${encodedUrl}`);
  };

  return (
    <button onClick={handleWhatsAppShare} className='whatsapp-button'>
      <FaWhatsappSquare/>
    </button>
  );
};

export default WhatsAppShareButton;
