import { React, useState } from "react";
import "./Contact.css";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import emailjs from "@emailjs/browser";
import { SiGooglemaps } from "react-icons/si";
import { AiOutlineMail } from "react-icons/ai";
import { MdContactPhone } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import image2 from "../../images/1.jpg";
import axios from "axios";
import Navbar2 from "../Navbar/Navbar2";
import "../Navbar/Navbar2.css";

const Contact = () => {
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_9sb0llz",
      "template_ad32do8",
      e.target,
      "ZmYGGwsO3XtD3pXT4"
    );

    setMailerState({
      email: "",
      name: "",
      message: "",
    });
  };
  return (
    <section className="contact-section">
      <Navbar2 />
      <div className="bg-contact"></div>
      <Container id="hire-container">
        <Row>
          <Col md={3}>
            <div className="div-icons">
              <SiGooglemaps className="contact-icons" />
              <h4>Vendndodhja</h4>
              <p>Rruga Bardhyl, Tirana</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="div-icons">
              <AiOutlineMail className="contact-icons" />
              <h4>Email</h4>
              <p>realestateidealhome@gmail.com</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="div-icons">
              <MdContactPhone className="contact-icons" />
              <h4>Telefon</h4>
              <p>+355 69 70 76 668</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="div-icons">
              <BiWorld className="contact-icons" />
              <h4>Website</h4>
              <p>idealhomerealestate.al</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="test-container">
        <Row>
          <Col lg="6">
            <div>
              <form className="form-wrapper" onSubmit={submitEmail}>
                <h2 className="contact-us">Na kontaktoni</h2>
                <input
                  type="text"
                  name="name"
                  placeholder="Emri*"
                  onChange={handleStateChange}
                  value={mailerState.name}
                ></input>
                <input
                  type="text"
                  name="email"
                  placeholder="Email*"
                  onChange={handleStateChange}
                  value={mailerState.email}
                ></input>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Shkruani mesazhin"
                  onChange={handleStateChange}
                  value={mailerState.message}
                ></textarea>
                <button className="hire-button" type="submit">
                  DËRGO
                </button>
              </form>
            </div>
          </Col>
          <Col lg="6" className="p-0">
            <div className="contact-image">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.9628676401903!2d19.81758547496361!3d41.33142079946656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13503181828dfd07%3A0x85e69135b193f330!2sIDEAL%20HOME%20REAL%20ESTATE!5e0!3m2!1ssq!2s!4v1703539565743!5m2!1ssq!2s"
                className="maps-frame"
                style={{border:"0"}}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Contact;
