import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import about from "../../images/about1.jpg";
import "./About.css";

const About = () => {
  return (
    <section id="about-section">
      <Container>
        <Row>
          <Col md="6">
            <div className="about-div">
              <img className="about-image" src={about}></img>
            </div>
          </Col>
          <Col md="6">
            <div className="about-description-div">
              <h5 className="first-title">Detajet bëjnë diferencën</h5>
              <h3 className="second-title">Unë jam Rajmonda Cala,</h3>
              <p className="about-description">
                Avokate e specializuar në Itali dhe aktualisht
                administratore në IDEAL HOME REAL ESTATE. Gjatë dy viteve të
                fundit, kam kaluar në botën e ndritshme dhe sfiduese të pasurisë
                së paluajtshme. Roli im nuk shtrihet thjesht përtej
                transaksioneve, por është për të krijuar lidhje të vërteta me
                klientët, për të kuptuar aspiratat e tyre dhe për t'i udhëzuar
                ata në një nga vendimet më të rëndësishme të jetës së tyre:
                blerjen e një Pasurie te Paluajteshme. 
                <br/>Një nga gurët e ndërtimit të profesionit tim është negocimi në emër të klientëve, duke
                ndërmjetësuar mes blerësve dhe shitësve me profesionalizëm dhe
                finesë. Çdo negociatë është dëshmi e angazhimit tim, duke
                siguruar që të dy palët të gjejnë vlerë dhe kënaqësi. Në këtë
                industri që ndryshon vazhdimisht, krenohem në mbajtjen e hapit
                me trendet, duke ofruar zgjidhje të personalizuara dhe gjithmonë
                duke e përkrahur interesin më të mirë të klientëve. 
                <br/>Në IDEAL HOME, ne kemi përmbushur nevojat e mijëra familjarëve dhe
                investitorëve në zona të ndryshme të Tiranës. Së bashku, ne kemi
                shumë vite përvojë në pasuri të paluajtshme, duke i përshtatur
                kërkesat e klientëve me shtëpitë e ëndrrave të tyre.<br/><span className="last-line">Ju lutemi
                na kontaktoni dhe ne do t'ju shërbejmë me të gjitha mundësitë
                tona!</span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
