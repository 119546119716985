import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaHome } from "react-icons/fa";
import { RiAdminFill, RiUser3Fill } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { IoFilterOutline } from "react-icons/io5";

import logo from '../../images/logo.png';
import './Sidebar.css';

const StyledSideNav = styled.div`
  position: fixed;
  height: 100%;
  width: 280px;
  z-index: 1;
  background-color: #222;
  overflow-x: hidden;
`;

const StyledNavItem = styled.div`
  height: 70px;
  text-align: justify;
  margin-bottom: 0;
  margin-left: 10%;
  a {
    font-size: 20px;
    color: ${(props) => (props.active ? '#5c7d95' : 'white')};
    text-decoration: none;
    :hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }
`;

const items = [
  { path: '/', name: 'Home', icon: <RiAdminFill />, key: 1 },
  { path: '/admin', name: 'Statistika', icon: <RiAdminFill />, key: 1 },
  { path: '/filter', name: 'Filter', icon: <IoFilterOutline />, key: 2 },
  { path: '/admin/allproperty', name: 'Pronat', icon: <FaHome />, key: 3 },
  { path: '/admin/user', name: 'User', icon: <RiUser3Fill />, key: 4 },
  { path: '/admin/documentation', name: 'Dokumentacioni', icon: <RiUser3Fill />, key: 5 },
  { path: '/admin/signout', name: 'Dil', icon: <FiLogOut />, key: 6 },
];

const Sidebar = () => {
  const [activePath, setActivePath] = useState(window.location.pathname);

  const onItemClick = (path) => {
    setActivePath(path);
  };

  return (
    <StyledSideNav>
      <a href="/">
        <img
          src={logo}
          alt="img"
          style={{ textAlign: 'center', width: '80%', marginLeft: '2%', marginTop: '5%', marginBottom: '15%' }}
        ></img>
      </a>
      {items.map((item) => (
        <NavItem
          key={item.key}
          path={item.path}
          name={item.name}
          icon={item.icon}
          onItemClick={onItemClick}
          active={item.path === activePath}
        />
      ))}
    </StyledSideNav>
  );
};

const NavItem = ({ path, name, icon, onItemClick, active }) => {
  const handleClick = () => {
    onItemClick(path);
  };

  return (
    <StyledNavItem active={active}>
      <Link to={path} onClick={handleClick}>
        <span className="side-icon">{icon}</span>
        {name}
      </Link>
    </StyledNavItem>
  );
};

export default Sidebar;
