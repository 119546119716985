import React from 'react'
import "./PronaAdminPanel.css"
import { Link } from "react-router-dom";
import Sidebar from '../Sidebar/Sidebar';

import { FaPlusCircle } from 'react-icons/fa';
import { IoEye } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';

const PronaAdminPanel = () => {
  const {currentUser} = useSelector((state)=>  state.user);
  return (
    <section>
    <Sidebar/>
    <div className='div-button'>
      <div className='shto-button'>
          <Link className="shto_res" to="/admin/addproperty"> Shto nje prone <FaPlusCircle className="plus_sign" /></Link>
      </div>
      <div className='shiko-button'>
        {
          currentUser.role == 'admin' ? <Link className="shiko_res" to="/admin/seeproperty"> Shiko pronat me qera<IoEye className="eye_sign" /></Link>:<Link className="shiko_res" to={`/admin/seeproperty/${currentUser._id}`}> Shiko pronat me qera<IoEye className="eye_sign" /></Link>
        }  
      </div>
      <div className='shiko-button mt-5'>
        {
          currentUser.role == 'admin' ? <Link className="shiko_res" to="/admin/seepropertysold"> Shiko pronat ne shitje<IoEye className="eye_sign" /></Link>:<Link className="shiko_res" to={`/admin/seepropertysold/${currentUser._id}`}> Shiko pronat ne shitje<IoEye className="eye_sign" /></Link>
        }  
      </div>
     
    </div>
    </section>
  )
}

export default PronaAdminPanel