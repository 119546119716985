import React from "react";
import Navbar2 from "../Navbar/Navbar2";
import "./Services.css";
import { Container, Col, Row } from "react-bootstrap";
import firstservice from "../../images/forrent.png";
import secondservice from "../../images/forsale.png";
import thirdservice from "../../images/otherservice.png";
import ealbania from "../../images/ealbania.jpg"
import Footer from "../Footer/Footer";

const Services = () => {
  return (
    <section className="services">
      <Navbar2 />
      <div className="bg-services"></div>
      <Container>
        <Row>
          <Col lg="6">
            <div>
              <img className="first-service" src={firstservice} />
            </div>
          </Col>
          <Col lg="6">
            <div className="fist-service-div">
              <h4>Ndërmjetësim Qiradhënie</h4>
              <p>
                Ofrimi i shërbimit të ndërmjetësimit nga agjensia për dhënien me
                qira e pronës së paluajtshme që qiramarrësi ka në pronësinë e
                tij. Agjensia do të ofrojë shërbime ndërmjetësimi për dhënien me
                qira të pronës duke kërkuar, marketuar, negociuar në tregun e
                pasurive të paluajtshme për të gjetur një qiramarrës për pronën
                që qiradhënësi zotëron. Për ofrimin e shërbimeve të
                ndërmjetësimit, qiramarrësi do t’i paguajë agjensisë ½ (gjysëm)
                qira mujore për kontrata nga 0-3 vite. Për kontrata mbi 3 vite
                tarifa e shërbimit të agjensisë është 1 (një) qira mujore,
                ndërsa qiradhënësi do t’i paguajë agjensisë 1 (një) qira mujore
                për kontrata nga 0-3 vite. Për kontrata mbi 3 vite tarifa e
                shërbimit të agjensisë është 2 (dy) qira mujore.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="fist-service-div">
              <h4>Ndërmjetësim Shitje-Blerje</h4>
              <p>
                Ofrimi i shërbimit të ndërmjetësimit nga agjensia për
                shitjen/blerjen e pronës së paluajtshme që shitësi ka në
                pronësinë e tij. Agjensia do të ofrojë shërbime ndërmjetësimi
                për shitjen/blerjen duke kërkuar, marketuar, negociuar në tregun
                e pasurive të paluajtshme për të gjetur një blerës për pronën që
                shitësi zotëron. Për ofrimin e shërbimeve të ndërmjetësimit,
                shitësi do t’i paguajë agjensisë 3% të vlerës totale të cmimit
                të shitjes, ndërsa blerësi do t’i paguajë agjensisë 1% të vlerës
                totale të cmimit të shitjes.
              </p>
            </div>
          </Col>
          <Col lg="6">
            <div>
              <img className="first-service" src={secondservice} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div>
              <img className="first-service-big" src={thirdservice} />
            </div>
          </Col>
          <Col lg="6">
            <div className="fist-service-div">
              <h4>Konsulencë imobiliare dhe juridike</h4>
              <p>
                Ofrojmë konsulencë imobilare për shitje, blerje, qiramarrje,
                qiradhënie për personat që interesohen të investojnë në prona të
                patundshme në të gjithë Tiranën dhe jo vetëm. Ne jemi të gatshëm
                të ofrojmë mundësitë më të mira për të gjetur ambjentin ideal
                dhe për të nxjerë pronën tuaj në treg. Garantojmë efikasitet dhe
                profesionalizëm në cdo shërbim.IDEAL HOME ofron konsulencë
                ligjore dhe siguron përfaqësim ligjor të klientëve, në fusha të
                ndryshme të së drejtës civile, tregtare, dhe administrative,
                duke i përfaqësuar klientët në të gjitha shkallët e gjykimit në
                çështje me juridiksion kombëtar. Shërbimet dhe konsulencat
                ligjore të specializuara dhe shumë specifike i ofrohen bizneseve
                dhe individëve që operojnë në sektorë të ndryshëm të ekonomisë,
                sepse cdo biznes e ka të domosdoshme të orjentohet drejt në
                raport me ligjin gjatë gjithë fazave të veprimtarisë së tij e
                vecanëerisht në kushtet kur legjislacioni është shumë dinamik
                dhe pëson ndryshime të herëpasherëshme. Shërbimi ynë i
                konsulencës juridike synon që të informojë dhe ti ndihmojë
                klientët se si të zbulojnë dhe zvogëlojnë riskun që vjen si
                rezultat i çështjeve juridike që lindin gjatë aktivitetit të një
                biznesi. IDEAL HOME ofron shërbimet juridike si më poshtë:
                <ul>
                  <li>Konsulencë juridike dhe përfaqësim ligjor</li>
                  <li>
                    Hartimin e dokumentacionit ligjor të të gjitha fushat
                    (civile, administrative, tregtare, Pronësisë)
                  </li>
                  <li>
                    Përgatitja e kërkesë padive ose kërkesave për cështje
                    gjyqësore
                  </li>
                  <li>
                    Përgatitja kërkesave ankimore, rekurseve kundër vendimeve
                    gjyqësore, atyre të organit Arbitrazhit ose të organeve të
                    administratës publike
                  </li>
                  <li>
                    Përgatitja e ankesave dhe kërkesave të personave fizik ose
                    juridic
                  </li>
                  <li>
                    Përpilimi i akteve që i nënshtrohen veprimeve noteriale
                  </li>
                  <li>
                    Hartimi i kontratave , marrëveshjeve, si dhe i çdo dokumenti
                    tjetër që sjell ose synon të sjellë pasoja juridike
                  </li>
                  <li>
                    Përfaqësimi i personave fizik ose juridik në çështjet civile
                    dhe administrative, në Gjykatë, në Arbitrazh dhe në organe
                    të tjera të administratës publike
                  </li>
                  <li>
                    Veprime të tjera ndihmëse juridike që parashikohen në ligje
                    të vecanta dhe akte të ndryshme ligjore
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
          <div className="fist-service-div">
              <h4>Shërbime e-albania</h4>
              <p>
                Zyra me një ndalesë, shërbime për ju duke në ndihmë të gjithë
                qytetarëve për aplikimet e ndryshme në sistemet online si dhe
                nxjerrjen e vërtetimeve sipas kërkesës së qytetarit në kohë
                reale. Me prezencën e qytetarit në zyrën tonë, pas nënshkrimit
                të formularit të “Autorizimit të përdorimit të të dhënave
                personale”, stafi ynë ju vjen në ndihmë për të kryer aplikime
                dhe nxjerrje të vërtetimeve online nga sistemi e-Albania dhe jo
                vetëm, për shërbime të ndryshme të drejtorive shtetërore si:
                Ndër shërbimet (aplikimet/vërtetimet) më të përodura nga
                qytetarët në zyrën tonë së fundmi listojmë:
                <ul>
                  <li>Çertifikatë familjare</li>
                  <li>Çertifikatë personale</li>
                  <li>Çertifikatë nga akti i martesës</li>
                  <li>
                    Lëshim ose rinovim i pasaportës, Gjobat-kundravajtjet
                    rrugore
                  </li>
                  <li>Fatura e energjisë elektrike</li>
                  <li>Konfirmim për gjendjen aktive të mjetit</li>
                  <li>Vërtetim për pagimin e kontributeve për individin</li>
                  <li>Aplikim për lëshim kopje kartele të pasurisë</li>
                  <li>Aplikim për lëshim vërtetim pronësie</li>
                  <li>Kërkesë për legalizim ndërtimi pa leje</li>
                  <li>Aplikim për dalje në pension</li>
                  <li>Vërtetim masë pensioni</li>
                  <li>
                    Çertifikatë vaksinimi, Denoncim i humbjes së dokumentit të
                    identifikimit (Kartë ID/Pasaportë) apo lejës së drejtimit
                    (Patentës)
                  </li>
                  <li>Leje për dalje</li>
                  <li>Leje qarkullimi për nevojat e subjekteve</li>
                  <li>Vërtetim për pagim detyrimesh</li>
                  <li>Karta e shëndetit</li>
                  <li>Dëshmi Penaliteti</li>
                  <li>Aplikim për leje pune, etj</li>
                </ul>
              </p>
            </div>
            
          </Col>
          <Col lg="6">
          <div>
              <img className="first-service" src={ealbania} />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </section>
  );
};

export default Services;
