import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Documentation.css";
import { useSelector } from "react-redux";

const Documentation = () => {
  const [formData, setFormData] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [showListingError, setShowListingError] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint;
        let url;
        currentUser.role == 'admin' ? url = `${process.env.REACT_APP_BASE_URL}/user/listings` : url = `${process.env.REACT_APP_BASE_URL}/user/listings/${currentUser._id}`;
        endpoint = url;

        const res = await fetch(endpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.success === false) {
          setShowListingError(true);
          return;
        }

        setFormData(data);
      } catch (error) {
        setShowListingError(true);
      }
    };

    fetchData();
  }, [reloadData]);


  const handleDocumentDelete = async (listingIndex, documentIndex) => {
    if (window.confirm("A jeni te sigurt qe doni ta fshini kete dokument?")) {
      try {
        const listingId = formData[listingIndex]._id;
  
        // Make a DELETE request to your server
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/listing/deleteDocument/${listingId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ documentIndex }), // Send the document index to delete
        });
  
        const data = await res.json();
  
        if (data.success) {
          // Create a new state object based on the current state
          const updatedFormData = [...formData];
          // Remove the selected document from the files array
          updatedFormData[listingIndex].files = updatedFormData[listingIndex].files.filter(
            (_, i) => i !== documentIndex
          );
          // Update the state
          setFormData(updatedFormData);
          // Trigger a re-render by updating the state
          setReloadData(!reloadData);
        } else {
          console.error("Error deleting document:", data.message);
        }
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  return (
    <div>
      <Sidebar />
      {showListingError ? (
        <p>Ka nje problem me shfaqjen e te dhenave</p>
      ) : formData.length > 0 ? (
        <table striped bordered hover size="sm" className="property-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Dokumentacioni</th>
              <th>Data</th>
              <th>Veprimet</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((item,listingIndex) => (
              item.files.map((file, documentIndex) => (
                <tr key={`${item._id}-${documentIndex}`}>
                  <td>{item._id}</td>
                  <td>{file}</td>
                  <td>
                    {new Date(item.createdAt).toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </td>
                  <td>
                    {currentUser.role === "admin" && (
                      <div>
                        <button
                          className="delete-listing-button"
                          onClick={() => handleDocumentDelete(listingIndex, documentIndex)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      ) : (
        <p>Ky user nuk ka asnje prone me dokumentacion ne sistem</p>
      )}
    </div>
  );
};

export default Documentation;
