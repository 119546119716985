import React, { useState, useEffect } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as Link } from "react-router-hash-link";
import "../Navbar/Navbar.css";
import logo from "../../images/logo2.png";
import "./Hero.css";
import { Col, Row } from "react-bootstrap";
import {
  FaHome,
  FaBed,
  FaEuroSign,
  FaSearchengin,
  FaCity,
  FaBath,
} from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineArrowsPointingOut } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Select from 'react-select'

const Hero = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFiltraAvancuar, setShowFiltraAvancuar] = useState(false);
  const [bedroom, setBedroom] = useState();
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [tipology, setTipology] = useState([]);
  const [sidebardata, setSidebarData] = useState({
    type: "all",
    siperfaqeMin: "",
    siperfaqeMax: "",
    cmimiMin: "",
    cmimiMax: "",
    tipologji: "",
    zona: [],
    city: "",
    name: "",
    bedroom: "",
    bathroom: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const token = localStorage.getItem('token');
  const [options, setOptions] = useState([]);


  useEffect(() => {
    const fetchCities = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
          
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setCity(data);
    };
    const fetchAreas = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/areas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      const sortedAreas = data.sort((a, b) => a.name.localeCompare(b.name));
      setArea(sortedAreas);
      const options = sortedAreas.map((item) => ({
        key: item.name,
        value: item.name
      }));
      setOptions(options);
    };
    const fetchTipologies = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/tipologies", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setTipology(data);
    };

    fetchCities();
    fetchAreas();
    fetchTipologies();
  }, []);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const changeFilterMode = (e) => {
    e.preventDefault();
    setShowFiltraAvancuar(!showFiltraAvancuar);
  };

  //Kalimi nga formati per pc ne tablet/telefona
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);


  const handleChange = (e) => {
    if (
      e.target.id === "all" ||
      e.target.id === "qira" ||
      e.target.id === "shitje"
    ) {
      setSidebarData({ ...sidebardata, type: e.target.id });
    }

    if (e.target.id === "tipologji") {
      setSidebarData((sidebardata) => {
        const updatedData = { ...sidebardata, tipologji: e.target.value };
        return updatedData; // Return the updated state
      });
    }
    if (e.target.id === "bedroom") {
      setSidebarData({ ...sidebardata, bedroom: e.target.value.toString() });
    }
    if (e.target.id === "bathroom") {
      setSidebarData({ ...sidebardata, bathroom: e.target.value.toString()});
    }
    if (e.target.id === "cmimiMin") {
      setSidebarData({ ...sidebardata, cmimiMin: parseFloat(e.target.value) });
    }
    if (e.target.id === "cmimiMax") {
      setSidebarData({ ...sidebardata, cmimiMax: parseFloat(e.target.value) });
    }
    if (e.target.id === "siperfaqeMin") {
      setSidebarData({
        ...sidebardata,
        siperfaqeMin: parseFloat(e.target.value),
      });
    }
    if (e.target.id === "siperfaqeMax") {
      setSidebarData({
        ...sidebardata,
        siperfaqeMax: parseFloat(e.target.value),
      });
    }
    if (e.target.id === "name") {
      setSidebarData({ ...sidebardata, name: e.target.value });
    }

    if (e.target.id === "city") {
      setSidebarData({ ...sidebardata, city: e.target.value });
    }
  };
  // Handle change for react-select
  const handleSelectChange = selectedOptions => {
      const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
      setSidebarData({ ...sidebardata, zona: selectedValues });
  };
  // Format the value for react-select
  const selectedValues = sidebardata.zona.map(value => ({
    label: value,
    value: value
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams();
    urlParams.set("type", sidebardata.type);
    urlParams.set("siperfaqeMin", sidebardata.siperfaqeMin);
    urlParams.set("siperfaqeMax", sidebardata.siperfaqeMax);
    urlParams.set("cmimiMin", sidebardata.cmimiMin);
    urlParams.set("cmimiMax", sidebardata.cmimiMax);
    urlParams.set("tipologji", sidebardata.tipologji);
    if (sidebardata.zona) {
      sidebardata.zona.forEach((zona) => {
        urlParams.append("zona", zona);
      });
    }
    urlParams.set("city", sidebardata.city);
    urlParams.set("name", sidebardata.name);
    urlParams.set("bedroom", sidebardata.bedroom);
    urlParams.set("bathroom", sidebardata.bathroom);

      const searchQuery = urlParams.toString();
      setLoading(true);
      setShowMore(false);
      try{
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/listing/get?${searchQuery}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
  
        if (data.length > 8) {
          setShowMore(true);
        } else {
          setShowMore(false);
        }
        setListings(data);
        navigate(`/get?${searchQuery}`,{ state: { listings: data } });
        setLoading(false);
      }
      catch(error){
        setLoading(false);
      }
     
    }

  return (
    <section className="landing">
      <div className="bg">
        <section id="navbar">
          <div className="logo">
            <Link className="navbarLinks" smooth="true" to="">
              <img className="navbar-logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="all-items">
            {(toggleMenu || screenWidth > 900) && (
              <ul className="list ms-auto">
                <li className="items">
                  <Link className="navbarLinks" smooth="true" to="">
                    Kreu
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks" smooth="true" to="#about-section">
                    Rreth nesh
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks" to={"/dokumentacion"}>
                    Dokumentacion
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks" to={"/services"}>
                    Shërbimet
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks" smooth="true" to="#prona-container">
                    Prona
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks" to="/admin">
                    Admin
                  </Link>
                </li>
                <li className="items">
                  <Link className="navbarLinks contact" smooth="true" to="/contact">
                    Kontakt
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <button onClick={toggleNav} className="menu-but">
            <FontAwesomeIcon icon={faBars} />
          </button>
        </section>
        <div className="big-filter">
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="property-options">
              <div>
                <ul className="property-list">
                  <li>
                    {" "}
                    <input
                      type="radio"
                      className="btn-check"
                      name="business_type"
                      value="all"
                      id="all"
                      autoComplete="off"
                      checked={sidebardata.type === "all"}
                      onChange={handleChange}
                    />
                    <label className="property-label" htmlFor="all">
                      Të gjitha
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      className="btn-check"
                      name="business_type"
                      value="shitje"
                      id="shitje"
                      autoComplete="off"
                      checked={sidebardata.type === "shitje"}
                      onChange={handleChange}
                    />
                    <label className="property-label" htmlFor="shitje">
                      Në shitje
                    </label>
                  </li>
                  <li>
                    {" "}
                    <input
                      type="radio"
                      className="btn-check"
                      name="business_type"
                      value="qira"
                      id="qira"
                      autoComplete="off"
                      checked={sidebardata.type === "qira"}
                      onChange={handleChange}
                    />
                    <label className="property-label" htmlFor="qira">
                      Me qera
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="property-search">
              <Row className="g-0">
                <Col lg="8">
                  <Row className="g-0">
                    <Col lg="6">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaHome></FaHome>
                        </span>
                        <select
                          id="tipologji"
                          value={sidebardata.tipologji}
                          onChange={handleChange}
                        >
                          <option value="">Zgjidhni një tipologji</option>
                          {tipology.map((item) => (
                            <option value={item.name} key={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="property-data">
                        <span className="property-icon">
                          <SiGooglemaps></SiGooglemaps>
                        </span>
                        <Select
                          id="zona"
                          value={selectedValues}
                          onChange={handleSelectChange}
                          isMulti
                          options={options.map(option => ({
                            label: option.key,
                            value: option.value
                          }))}
                          placeholder="Zgjidhni zonën"
                        >
                        </Select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="4">
                  <Row className="g-0">
                    <Col md="6">
                      <div className="group-button">
                        <span className="property-icon">
                          <IoFilterOutline></IoFilterOutline>
                        </span>
                        <button
                          onClick={changeFilterMode}
                          className="filter-button"
                        >
                          Filtra
                        </button>
                        <button className="form-button" type="submit">
                          Kërko
                        </button>
                      </div>
                    </Col>
                    {/* <Col md="6"></Col> */}
                  </Row>
                </Col>
              </Row>

              {showFiltraAvancuar && (
                <div className="filtra-avancuar2">
                  <hr className="horizontal-line" />
                  <Row className="g-0 mb-4">
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaBed></FaBed>
                        </span>
                        <select
                          id="bedroom"
                          value={sidebardata.bedroom}
                          onChange={handleChange}
                        >
                          <option value="">Dhoma gjumi</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                        </select>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaBath></FaBath>
                        </span>
                        <select
                          id="bathroom"
                          value={sidebardata.bathroom}
                          onChange={handleChange}
                        >
                          <option value="">Tualete</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                        </select>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaEuroSign></FaEuroSign>
                        </span>
                        <input
                          name="cmimiMin"
                          id="cmimiMin"
                          placeholder="Cmimi min"
                          value={sidebardata.cmimiMin}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaEuroSign></FaEuroSign>
                        </span>
                        <input
                          name="cmimiMax"
                          id="cmimiMax"
                          placeholder="Cmimi max"
                          value={sidebardata.cmimiMax}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-0">
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaSearchengin></FaSearchengin>
                        </span>
                        <input
                          name="referenca"
                          id="name"
                          placeholder="ID/Referenca"
                          value={sidebardata.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <FaCity></FaCity>
                        </span>
                        <select
                          id="city"
                          value={sidebardata.qyteti}
                          onChange={handleChange}
                        >
                          <option value="">Zgjidhni qytetin</option>
                          {city.map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                          {/* Shtoni opsionet e tjera të vendndodhjes sipas nevojës */}
                        </select>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <HiOutlineArrowsPointingOut></HiOutlineArrowsPointingOut>
                        </span>
                        <input
                          name="siperfaqeMin"
                          id="siperfaqeMin"
                          placeholder="Sipërfaqe Min"
                          value={sidebardata.siperfaqeMin}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="property-data">
                        <span className="property-icon">
                          <HiOutlineArrowsPointingOut></HiOutlineArrowsPointingOut>
                        </span>
                        <input
                          name="siperfaqeMax"
                          id="siperfaqeMax"
                          placeholder="Sipërfaqe Max"
                          value={sidebardata.siperfaqeMax}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Hero;
