import React, { useState } from "react";
import "./Signin.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signinStart,
  signinSuccess,
  signinFailure,
} from "../../redux/user/userSlice";

const Signin = () => {
  const [formData, setFormData] = useState({});
  const { loading, error } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStateChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(signinStart());
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/auth/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(signinFailure(data.message));
        return;
      }
      dispatch(signinSuccess(data.rest));
      window.localStorage.setItem("token", data.token)
      navigate("/admin");
    } catch (error) {
      dispatch(signinFailure(error.message));
    }
  };
  return (
    <div className="center-container">
      <div className="signin-container">
        <h4 className="signin-title">Sign in</h4>
        <form className="signin-form" onSubmit={handleSubmit}>
          <label>Email</label>
          <input type="email" id="email" onChange={handleStateChange} />
          <label>Password</label>
          <input type="password" id="password" onChange={handleStateChange} />
          <button className="signin-button" disabled={loading}>
            {loading ? "Loading..." : "SUBMIT"}
          </button>
        </form>
        {error && <p className="errorMessage">{error}</p>}
      </div>
    </div>
  );
};

export default Signin;
