// Updatimi i userave
import React, { useState,useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
} from "../../redux/user/userSlice";
import Sidebar from "../Sidebar/Sidebar";
import "./UpdateUsers.css";
import { useNavigate,useParams } from "react-router-dom";
import { app } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const UpdateUsers = () => {
  const { id } = useParams(); // Get the user ID from URL params
  const [userData, setUserData] = useState({
    username:"",
    email:"",
    tel:+355,
    role:"",
    agentUrls:[]
  });
  const [agentUrls, setAgentUrls] = useState([]);
  const [imageuploading, setImageUploading] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    setUserData((userData) => {
      if (type === "checkbox") {
        return { ...userData, [id]: checked };
      } else {
        return { ...userData, [id]: value };
      }
    });
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/profile/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await res.json();
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleSubmit = async (e) => {
    console.log('erdhiii');
    e.preventDefault();
    try {
      dispatch(updateUserStart());
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/update/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        }
      );
      const data = await res.json();
      if (data.success === false) {
        dispatch(updateUserFailure(data.message));
        return;
      }
      dispatch(updateUserSuccess(data));
      navigate('/admin/user');
    } catch (error) {
      dispatch(updateUserFailure(error.message));
    }
  };
  const handleImageSubmit = (e) => {

    if (agentUrls.length > 0 && agentUrls.length + userData.agentUrls.length < 3) {
      setImageUploading(true);
      setImageUploadError(false);
      const promises = [];

      for (let i = 0; i < agentUrls.length; i++) {
        promises.push(storeImage(agentUrls[i]));
      }
      Promise.all(promises)
        .then((urls) => {
          setUserData({
            ...userData,
            agentUrls: userData.agentUrls.concat(urls),
          });
          setImageUploadError(false);
          setImageUploading(false);
        })
        .catch((err) => {
          setImageUploadError("Image upload failed (2 mb max per image)");
          setImageUploading(false);
        });
    } else {
      setImageUploadError("Mund te uploadosh vetem 1 imazh ");
      setImageUploading(false);
    }
  };
  const storeImage = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          reject(error);
        },
        async () => {
          try {
            // Get the download URL of the uploaded image
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      )
    }, 'image/png');
  };
  const handleRemoveImage = (index) => {
    setUserData({
      ...userData,
      agentUrls: userData.agentUrls.filter((_, i) => i !== index),
    });
  };

  return (
    <div className="full_editor">
      <Sidebar />
      <div className="myformuser">
        <Form className="form-test" onSubmit={handleSubmit}>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Username</label>
              <input
                required="true"
                type="username"
                className="update-input"
                defaultValue={userData.username}
                onChange={handleChange}
                id = "username"
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Email</label>
              <input
                required="true"
                type="Email"
                className="update-input"
                defaultValue={userData.email}
                id = "email"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Password</label>
              <input
                required
                type="password"
                className="update-input"
                onChange={handleChange}
                id = "password"
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Tel</label>
              <input
                required
                type="tel"
                className="update-input"
                value={userData.tel}
                id = "tel"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Role</label>
              <select
                defaultValue={userData.role}
                onChange={handleChange}
                required
                className="update-input"
                id = "role"
              >
                <option value="" disabled selected>
                  Choose Role
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
            <label style={{ textAlign: "center" }}>Imazhi per agjentin</label>
            <input
              onChange={(e) => setAgentUrls(e.target.files)}
              className="p-3 border border-gray-300 rounded w-full"
              type="file"
              id="images"
              accept="image/*"
              multiple
            />
            <button
              type="button"
              disabled={imageuploading}
              onClick={handleImageSubmit}
              className="p-3 text-green-700 border border-green-700 rounded uppercase hover:shadow-lg disabled:opacity-80"
            >
            {imageuploading ? "Uploading..." : "Upload"}
            </button>            
            </Col>
            <p className="text-red">
                  {imageUploadError && imageUploadError}
                </p>
                {userData.agentUrls && userData.agentUrls.length > 0 &&
                  userData.agentUrls
                    .filter(url => url) // Filter out empty values
                    .map((url, index) => (
                      <div key={index} className="">
                        <img
                          src={url}
                          alt="listing image"
                          className="listing-image"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveImage(index)}
                          className="delete-listing-image"
                        >
                          Delete
                        </button>
                      </div>
                    ))}
          </Row>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Agent</label>
              <input
                type="checkbox"
                className="update-checkbox"
                id="isAgent"
                checked={userData.isAgent}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <label style={{ textAlign: "center" }}>Confirmed</label>
              <input
                type="checkbox"
                className="update-checkbox"
                id="confirmed"
                checked={userData.confirmed}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <div>
            <button className="butoni_update_user">Update User</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateUsers;
