import { useState, useEffect } from "react";
import { app } from "../../firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "./PronaEditor.css";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import Sidebar from "../Sidebar/Sidebar";
import logo from "../../images/logo2.png";
import secondLogo from "../../images/exclusive.png"

export default function PronaEditor() {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [formData, setFormData] = useState({
    imageUrls: [],
    name: "",
    shortdescription: "",
    longdescription: "",
    city: "",
    area: "",
    type: "qira",
    bedrooms: 1,
    bathrooms: 1,
    price: 50,
    offer: false,
    homepage: false,
    bestproperty: false,
    surface: 30,
    floor: 1,
    tipology: "",
    documentation: "",
    agent: "",
    flat: "",
    elevator: "",
    coin: "",
    advertisedPrice: 100,
    phoneNumber: "",
    commission: 1,
    user: "",
    files: [],
  });
  const [imageUploadError, setImageUploadError] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [imageuploading, setImageUploading] = useState(false);
  const [fileuploading, setFileUploading] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [tipology, setTipology] = useState([]);
  const [name, setName] = useState([]);
  const [user, setUser] = useState([]);
  const[filteredData,setFilteredData] = useState([]);
  const token = localStorage.getItem('token');
  const names = currentUser.username.split(" "); // Split username into an array of words
  
  // Take the first letter of the first word (first name) and capitalize it
    const firstNameInitial = names[0].charAt(0).toUpperCase();
  
  // Take the first letter of the last word (last name) and capitalize it
    const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
    
  // Concatenate the first letters of first and last name
    const loggedInUserPrefix = firstNameInitial + lastNameInitial;
    // console.log('loggedInUserPrefix',loggedInUserPrefix);

  useEffect(() => {
    const fetchCities = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setCity(data);
    };
    const fetchTipologies = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/tipologies", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      setTipology(data);
    };
    const fetchAreas = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/areas", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.success === false) {
        return;
      }
      const sortedAreas = data.sort((a, b) => a.name.localeCompare(b.name));
      setArea(sortedAreas);
    };
    const fetchProperties = async () => {
      let endpoint;
      endpoint = process.env.REACT_APP_BASE_URL+'/user/listings';
      const res = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await res.json();
      if (data.success === false) {
        return;
      }
      // Filter data to include only entries where the name starts with IH/${loggedInUserPrefix}
      const propertyData = data.filter(entry => entry.name.startsWith(`IH/${loggedInUserPrefix}`));
      setFilteredData(propertyData);
    };

    const fetchUsers = async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/allUsers", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await res.json();

      if (data.success === false) {
        return;
      }

      setUser(data);
    };

    const fetchData = async () => {
      await Promise.all([
        fetchCities(),
        fetchTipologies(),
        fetchAreas(),
        fetchProperties(),
        fetchUsers()
      ]);
    };
  
    fetchData();
  }, []);
  useEffect(()=>{
    // console.log('filteredData',filteredData);
      const fetchIds = async () => {
        const res = await fetch(process.env.REACT_APP_BASE_URL+"/user/ids", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.success === false) {
          return;
        }
        const filteredIds = data.filter(id => id.name.startsWith(`IH/${loggedInUserPrefix}`));
        // console.log('filteredIds',filteredIds);
        // Exclude items present in filteredData from filteredIds
        const filteredIdsExcludingData = filteredIds.filter(id => !filteredData.some(entry => entry.name === id.name));
        // console.log('filteredIdsExcludingData',filteredIdsExcludingData)
        setName(filteredIdsExcludingData);
      };
    fetchIds();
    
   
  },[filteredData])

  const handleImageSubmit = (e) => {
    if (imageUrls.length > 0 && imageUrls.length + formData.imageUrls.length < 10) {
      setImageUploading(true);
      setImageUploadError(false);
      const promises = [];

      for (let i = 0; i < imageUrls.length; i++) {
        promises.push(storeImage(imageUrls[i]));
      }
      Promise.all(promises)
        .then((urls) => {
          setFormData({
            ...formData,
            imageUrls: formData.imageUrls.concat(urls),
          });
          setImageUploadError(false);
          setImageUploading(false);
        })
        .catch((err) => {
          setImageUploadError("Image upload failed (2 mb max per image)");
          setImageUploading(false);
        });
    } else {
      setImageUploadError("Mund te uploadosh vetem 9 imazhe ");
      setImageUploading(false);
    }
  };
  const handleFileSubmit = (e) => {
    if (files.length > 0 && files.length + formData.files.length < 4) {
      setFileUploading(true);
      setFileUploadError(false);
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        promises.push(storeFile(files[i]));
      }
      Promise.all(promises)
        .then((urls) => {
          setFormData({
            ...formData,
            files: formData.files.concat(urls),
          });
          setFileUploadError(false);
          setFileUploading(false);
        })
        .catch((err) => {
          setFileUploadError("File upload failed");
          setFileUploading(false);
        });
    } else {
      setFileUploadError("Mund te uploadosh vetem 3 dokumenta ");
      setFileUploading(false);
    }
  };

  const storeImage = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = downloadURL;
  
            image.onload = async () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
  
              canvas.width = image.width;
              canvas.height = image.height;
  
              ctx.drawImage(image, 0, 0);
  
              const logoImage = new Image();
              logoImage.crossOrigin = 'anonymous';
              logoImage.src = logo;
              const logoSizeRatio = 0.2;
  
              logoImage.onload = () => {
                const logoWidth = canvas.width * logoSizeRatio;
                const logoHeight = logoWidth * (logoImage.height / logoImage.width);
                // Draw the logo centered in the canvas
                const logoX = (canvas.width - logoWidth) / 2;
                const logoY = (canvas.height - logoHeight) / 2;
                ctx.globalAlpha = 0.7;
                ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
                ctx.globalAlpha = 1;
                if (formData.offer) {
                  // Load the second logo
                  const secondLogoImage = new Image();
                  secondLogoImage.crossOrigin = 'anonymous';
                  const secondLogoWidth = 150;
                  const secondLogoHeight = 150;
                  secondLogoImage.src = secondLogo;
  
                  secondLogoImage.onload = () => {
                    // Draw the second logo in the top-right corner
                    const secondLogoX = canvas.width - secondLogoWidth;
                    const secondLogoY = 0;
  
                    ctx.drawImage(secondLogoImage, secondLogoX, secondLogoY, secondLogoWidth, secondLogoHeight);
  
                    // Convert the canvas content to a new image URL
                    canvas.toBlob(async (blob) => {
                      // Upload the Blob to Firebase Storage
                      const uploadTask = uploadBytesResumable(storageRef, blob);
  
                      uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          console.log(`Upload is ${progress}% done`);
                        },
                        (error) => {
                          reject(error);
                        },
                        async () => {
                          try {
                            // Get the download URL of the uploaded image
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve(downloadURL);
                          } catch (error) {
                            reject(error);
                          }
                        }
                      );
                    }, 'image/png');
                  };
                } else {
                  // If no second logo, proceed with the first canvas
                  canvas.toBlob(async (blob) => {
                    // Upload the Blob to Firebase Storage
                    const uploadTask = uploadBytesResumable(storageRef, blob);
  
                    uploadTask.on(
                      "state_changed",
                      (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% done`);
                      },
                      (error) => {
                        reject(error);
                      },
                      async () => {
                        try {
                          // Get the download URL of the uploaded image
                          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                          resolve(downloadURL);
                        } catch (error) {
                          reject(error);
                        }
                      }
                    );
                  }, 'image/png');
                }
              };
            };
          });
        }
      );
    });
  };
  
  const storeFile = async (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
           
            resolve(downloadURL);
          });
        }
      );
    });
  };
  
  const handleRemoveImage = (index) => {
    setFormData({
      ...formData,
      imageUrls: formData.imageUrls.filter((_, i) => i !== index),
    });
  };
  const handleRemoveFile = (index) => {
    setFormData({
      ...formData,
      files: formData.files.filter((_, i) => i !== index),
    });
  };

  const handleChange = (e) => {
    if (
      e.target.id === "shitje" ||
      e.target.id === "qira" ||
      e.target.id === "u shit" ||
      e.target.id === "u dha me qera" ||
      e.target.id === "rezervuar"
    ) {
      setFormData({
        ...formData,
        type: e.target.id,
      });
    }
    if (e.target.id === "offer") {
      setFormData({
        ...formData,
        [e.target.id]: e.target.checked,
      });
    }
    if (e.target.id === "homepage") {
      setFormData({
        ...formData,
        [e.target.id]: e.target.checked,
      });
    }
    if (e.target.id === "bestproperty") {
      setFormData({
        ...formData,
        [e.target.id]: e.target.checked,
      });
    }
    if (e.target.id === "tipology") {
      setFormData({
        ...formData,
        tipology: e.target.value,
      });
    }
    if (e.target.id === "city") {
      setFormData({
        ...formData,
        city: e.target.value,
      });
    }
    if (e.target.id === "coin") {
      setFormData({
        ...formData,
        coin: e.target.value,
      });
    }
    if (e.target.id === "area") {
      setFormData({
        ...formData,
        area: e.target.value,
      });
    }
    if (e.target.id === "id") {
      setFormData({
        ...formData,
        name: e.target.value,
      });
    }
    if (e.target.id === "agent") {
      setFormData({
        ...formData,
        agent: e.target.value,
      });
    }
    if (e.target.id === "user") {
      setFormData({
        ...formData,
        user: e.target.value,
      });
    }
    if (
      e.target.type === "number" ||
      e.target.type === "text" ||
      e.target.type === "textarea"
    ) {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.imageUrls.length < 1)
        return setError("Duhet te uploadosh te pakten nje imazh");
      setLoading(true);
      setError(false);
      const res = await fetch(process.env.REACT_APP_BASE_URL+"/listing/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          userRef: currentUser._id,
        }),
      });
      const data = await res.json();
      setLoading(false);
      if (data.success === false) {
        setError(data.message);
      }

      if (currentUser.role == "admin") {
        navigate(`/admin/seeproperty`);
      } else {
        navigate(`/admin/seeproperty/${data._id}`);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  return (
    <section>
      <Sidebar />
      <h1 className="add-property">Shto prona</h1>
      <form onSubmit={handleSubmit} className="add-property-form">
        <Container className="prona-container mb-4">
          <Row>
            <Col sm="12">
              <div className="form-input-item">
                <p>Id</p>
                <select
                  id="id"
                  value={formData.name}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled hidden>
                    Id
                  </option>
                  {name.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p>Pershkrim i shkurter</p>
                <textarea
                  type="text"
                  placeholder="Pershkrim i shkurter"
                  className="property-textarea"
                  id="shortdescription"
                  required
                  onChange={handleChange}
                  value={formData.shortdescription}
                  maxLength={70}
                />
              </div>
              <div>
                <p>Pershkrimi i detajuar </p>
                <textarea
                  type="text"
                  placeholder="Pershkrim i detajuar"
                  className="property-textarea"
                  id="longdescription"
                  required
                  onChange={handleChange}
                  value={formData.longdescription}
                />
              </div>
              <div>
                <p>Dokumentacioni</p>
                <input
                  type="text"
                  placeholder="Me hipoteke/Pa hipoteke"
                  className="property-input"
                  id="documentation"
                  required
                  onChange={handleChange}
                  value={formData.documentation}
                />
              </div>
              <div className="property-checkbox">
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="shitje"
                    className=""
                    onChange={handleChange}
                    checked={formData.type === "shitje"}
                  />
                  <span>Shitje</span>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="qira"
                    className=""
                    onChange={handleChange}
                    checked={formData.type === "qira"}
                  />
                  <span>Qira</span>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="offer"
                    className="w-5"
                    onChange={handleChange}
                    checked={formData.offer}
                  />
                  <span>Okazion</span>
                </div>
              </div>
              <div className="property-checkbox">
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="u shit"
                    className=""
                    onChange={handleChange}
                    checked={formData.type === "u shit"}
                  />
                  <span>U shit</span>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="u dha me qera"
                    className=""
                    onChange={handleChange}
                    checked={formData.type === "u dha me qera"}
                  />
                  <span>U dha me qira</span>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="rezervuar"
                    className="w-5"
                    onChange={handleChange}
                    checked={formData.type === "rezervuar"}
                  />
                  <span>Rezervuar</span>
                </div>
              </div>
              <div className="property-checkbox">
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="homepage"
                    className="w-5"
                    onChange={handleChange}
                    checked={formData.homepage}
                  />
                  <span>Homepage</span>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="bestproperty"
                    className="w-5"
                    onChange={handleChange}
                    checked={formData.bestproperty}
                  />
                  <span>Pronat e perzgjedhura</span>
                </div>
                <div className="form-input-item">
                  <p>Monedha</p>
                  <select
                    id="coin"
                    value={formData.coin}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Monedha</option>
                    <option value="Leke">Leke</option>
                    <option value="Euro">Euro</option>
                  </select>
                </div>
              </div>
              <div className="form-input">
                <div className="form-input-item">
                <p>Dhoma gjumi</p>
                  <input
                    type="number"
                    id="bedrooms"
                    min="1"
                    max="10"
                    required
                    onChange={handleChange}
                    value={formData.bedrooms}
                  />
                  
                </div>
                <div className="form-input-item">
                <p>Tualete</p>
                  <input
                    type="number"
                    id="bathrooms"
                    min="1"
                    max="10"
                    required
                    onChange={handleChange}
                    value={formData.bathrooms}
                  />
                  
                </div>
                <div className="form-input-item">
                <p>Kati</p>
                  <input
                    type="number"
                    id="floor"
                    min="0"
                    max="20"
                    required
                    onChange={handleChange}
                    value={formData.floor}
                  />
                  
                </div>
              </div>
              <div className="form-input">
                <div className="form-input-item">
                <p>Siperfaqja</p>
                  <input
                    type="number"
                    id="surface"
                    min="1"
                    max="300"
                    required
                    onChange={handleChange}
                    value={formData.surface}
                    step="0.01"
                  />
                 
                  <span className="text-xs">(m2)</span>
                </div>
                <div className="form-input-item">
                <p>Cmimi</p>
                  <input
                    type="number"
                    id="price"
                    min="50"
                    max="10000000"
                    required
                    onChange={handleChange}
                    value={formData.price}
                  />
                  <div className="flex flex-col items-center">
                   
                    {formData.type === "qira" && formData.coin === "Euro" && (
                      <span className="text-xs">(€ / muaj)</span>
                    )}
                    {formData.type === "qira" && formData.coin === "Lek" && (
                      <span className="text-xs">(L / muaj)</span>
                    )}
                    {formData.type === "shitje" && formData.coin === "Lek" && (
                      <span className="text-xs">(L)</span>
                    )}
                    {formData.type === "shitje" && formData.coin === "Euro" && (
                      <span className="text-xs">(€)</span>
                    )}
                  </div>
                </div>
                <div className="form-input-item">
                <p>Cmimi i reklamuar</p>
                  <input
                    type="number"
                    id="advertisedPrice"
                    min="50"
                    max="10000000"
                    required
                    onChange={handleChange}
                    value={formData.advertisedPrice}
                  />
                  <div className="flex flex-col items-center">
                   
                    {formData.type === "qira" && formData.coin === "Euro" && (
                      <span className="text-xs">(€ / muaj)</span>
                    )}
                    {formData.type === "qira" && formData.coin === "Lek" && (
                      <span className="text-xs">(L / muaj)</span>
                    )}
                    {formData.type === "shitje" && formData.coin === "Lek" && (
                      <span className="text-xs">(L)</span>
                    )}
                    {formData.type === "shitje" && formData.coin === "Euro" && (
                      <span className="text-xs">(€)</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-input">
                <div className="form-input-item">
                  <p>Tipologjia</p>
                  <select
                    id="tipology"
                    value={formData.tipology}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Tipologjia</option>
                    {tipology.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-input-item">
                  <p>Lloji i pallatit</p>
                  <input
                    type="text"
                    id="flat"
                    placeholder="i ri/i vjeter"
                    required
                    onChange={handleChange}
                    value={formData.flat}
                  />
                </div>
                <div className="form-input-item">
                  <p>Ashensor</p>
                  <input
                    type="text"
                    id="elevator"
                    placeholder="Po/Jo"
                    required
                    onChange={handleChange}
                    value={formData.elevator}
                  />
                </div>
              </div>
              <div className="form-input">
                <div className="form-input-item">
                  <p>Qyteti</p>
                  <select
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Qyteti</option>
                    {city.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-input-item">
                  <p>Zona</p>
                  <select
                    id="area"
                    value={formData.area}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Zona</option>
                    {area.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-input-item">
                  <p>Numri i pronarit</p>
                  <input
                    type="text"
                    id="phoneNumber"
                    placeholder="+prefix XX XX XX XXX"
                    required
                    onChange={handleChange}
                    value={formData.phoneNumber}
                  />
                </div>
              </div>
              <div className="form-input">
                <div className="form-input-item">
                  <p>Agjenti</p>
                  <select
                    id="agent"
                    value={formData.agent}
                    onChange={handleChange}
                    required
                  >
                    <option option value="">
                      {" "}
                      Zgjidhni agjentin{" "}
                    </option>
                    {user.map((item) =>
                      item.isAgent ? (
                        <option value={item.username}>{item.username}</option>
                      ) : null
                    )}
                  </select>
                </div>
                <div className="form-input-item">
                  <p>Personi menaxhues</p>
                  <select
                    id="user"
                    value={formData.user}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Zgjidhni userin</option>
                    <option value="Rajmonda">Rajmonda</option>
                    <option value="Serveti">Serveti</option>
                  </select>
                </div>
                <div className="form-input-item">
                  <p>Komisioni ne %</p>
                  <input
                    type="number"
                    id="commission"
                    required
                    onChange={handleChange}
                    value={formData.commission}
                    step="0.01"
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-4">
                <p className="font-semibold">
                  Images:
                  <span className="font-normal text-gray-600 ml-2">
                    Imazhi i pare do te jete cover (max 9)
                  </span>
                </p>
                <div className="flex gap-4">
                  <input
                    onChange={(e) => setImageUrls(e.target.files)}
                    className="p-3 border border-gray-300 rounded w-full"
                    type="file"
                    id="images"
                    accept="image/*"
                    multiple
                  />
                  <button
                    type="button"
                    disabled={imageuploading}
                    onClick={handleImageSubmit}
                    className="p-3 text-green-700 border border-green-700 rounded uppercase hover:shadow-lg disabled:opacity-80"
                  >
                    {imageuploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
                <p className="font-semibold">Files</p>
                <div className="flex gap-4">
                  <input
                    onChange={(e) => setFiles(e.target.files)}
                    className="p-3 border border-gray-300 rounded w-full"
                    type="file"
                    id="files"
                    accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    multiple
                  />
                  <button
                    type="button"
                    disabled={fileuploading}
                    onClick={handleFileSubmit}
                    className="p-3 text-green-700 border border-green-700 rounded uppercase hover:shadow-lg disabled:opacity-80"
                  >
                    {fileuploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
                <p className="text-red">
                  {imageUploadError && imageUploadError}
                </p>
                {formData.imageUrls.length > 0 &&
                  formData.imageUrls.map((url, index) => (
                    <div key={url} className="">
                      <img
                        src={url}
                        alt="listing image"
                        className="listing-image"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        className="delete-listing-image"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                {formData.files.length > 0 &&
                  formData.files.map((url, index) => {
                    const filename = url.substring(url.lastIndexOf("/") + 1);
                    return (
                      <div key={url} className="file-div">
                        <a>{filename}</a>
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(index)}
                          className="delete-listing-image"
                        >
                          Delete
                        </button>
                      </div>
                    );
                  })}
                <button
                  disabled={loading || imageuploading || fileuploading}
                  className="create-listing"
                >
                  {loading ? "Creating..." : "Krijo listimin"}
                </button>
                {error && <p className="">{error}</p>}
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </section>
  );
}
