import React, { useState, useEffect } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as Link } from "react-router-hash-link";
import "./Navbar2.css";
import logo from "../../images/logo2.png";



const Navbar2 = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  //Kalimi nga formati per pc ne tablet/telefona
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <section className="landing">
      <div className="bg-nav2">
        <section id="navbar">
          <div className="logo">
            <Link className="navbarLinks" to="/">
              <img className="navbar-logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="all-items2">
            {(toggleMenu || screenWidth > 900) && (
              <ul className="list ms-auto">
                <li className="items2">
                  <Link className="navbarLinks" to="/">
                    Kreu
                  </Link>
                </li>
                <li className="items2">
                  <Link className="navbarLinks" to="/admin">
                    Admin
                  </Link>
                </li>
                <li className="items2">
                  <Link className="navbarLinks contact" to="/contact">
                    Kontakt
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <button onClick={toggleNav} className="menu-but">
            <FontAwesomeIcon icon={faBars} />
          </button>
        </section>
      </div>
    </section>
  );
};

export default Navbar2;
