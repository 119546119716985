import React, { useState, useEffect } from "react";
import Navbar2 from "../Navbar/Navbar2";
import Footer from "../Footer/Footer";
import "./Rregullore.css";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { app } from "../../firebase";

const Rregullore = () => {
  const [licensimi, setLicensimiUrl] = useState(null);
  const [rregullore, setRregulloreUrl] = useState(null);
  const [kodiEtikes, setKodiEtikesUrl] = useState(null);

  useEffect(() => {
    const fetchDocumentUrl = async () => {
      const storageLicensimi = getStorage(app);
      const storageRefLicensimi = ref(
        storageLicensimi,
        "LICENSIMI RAJMONDA CALA.pdf"
      ); // Replace with your actual path

      const storageRregullore = getStorage(app);
      const storageRefRregullore = ref(
        storageRregullore,
        "RREGULLORE E BRENDSHME IDEAL HOME.pdf"
      ); // Replace with your actual path

      const storageKodiEtikes = getStorage(app);
      const storageRefKodiEtikes = ref(
        storageKodiEtikes,
        "KODI I ETIKES IDEAL HOME.pdf"
      ); // Replace with your actual path

      try {
        const urlLicensimi = await getDownloadURL(storageRefLicensimi);
        setLicensimiUrl(urlLicensimi);

        const urlRregullore = await getDownloadURL(storageRefRregullore);
        setRregulloreUrl(urlRregullore);

        const urlKodiEtikes = await getDownloadURL(storageRefKodiEtikes);
        setKodiEtikesUrl(urlKodiEtikes);
      } catch (error) {
        console.error("Error fetching document URL:", error);
      }
    };

    fetchDocumentUrl();
  }, []);

  return (
    <section>
      <Navbar2 />
      <div className="rules"></div>
      <h4 className="rule-title">Kliko linkun e mëposhtëm për të aksesuar licensimin</h4>
      <div className="rule-div">
        {licensimi ? (
          <a href={licensimi} target="_blank" rel="noopener noreferrer">
            Licensimi
          </a>
        ) : (
          <p>Licensimi është duke u ngarkuar...</p>
        )}
      </div>
      <h4 className="rule-title">Kliko linkun e mëposhtëm për të aksesuar rregulloren</h4>
      <div className="rule-div">
        {rregullore ? (
          <a href={rregullore} target="_blank" rel="noopener noreferrer">
            Rregullore
          </a>
        ) : (
          <p>Rregullorja është duke u ngarkuar...</p>
        )}
      </div>
      <h4 className="rule-title">Kliko linkun e mëposhtëm për të aksesuar kodin e etikës</h4>
      <div className="rule-div">
        {kodiEtikes ? (
          <a href={kodiEtikes} target="_blank" rel="noopener noreferrer">
            Kodi i etikës
          </a>
        ) : (
          <p>Kodi i etikës është duke u ngarkuar...</p>
        )}
      </div>
      <Footer />
    </section>
  );
};

export default Rregullore;
