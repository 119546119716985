import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Fragment } from "react";
import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Filter from "./components/Filter/Filter";
import Prona from "./components/Prona/Prona";
import Footer from "./components/Footer/Footer";
import Wallpaper from "./components/Wallpaper/Wallpaper";
import BestProperty from "./components/BestProperty/BestProperty";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Signup from "./components/Signup/Signup";
import Signin from "./components/Signin/Signin";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import Sidebar from "./components/Sidebar/Sidebar";
import { persistor, store } from "../src/redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PronaAdminPanel from "./components/PronaAdminPanel/PronaAdminPanel";
import PronaEditor from "./components/PronaEditor/PronaEditor";
import ListaPronave from "./components/ListaPronave/ListaPronave";
import User from "./components/User/User";
import Logout from "./components/Logout/Logout";
import SingleProperty from "./components/SingleProperty/SingleProperty";
import UpdateUsers from "./components/UpdateUsers/UpdateUsers";
import PronaByUser from "./components/PronaByUser/PronaByUser";
import PropertyEdit from "./components/PropertyEdit/PropertyEdit";
import AllProperties from "./components/AllProperties/AllProperties";
import Documentation from "./components/Documentation/Documentation";
import Services from "./components/Services/Services";
import Rregullore from "./components/Rregullore/Rregullore";
import ListaPronaveShitje from "./components/ListaPronave/ListaPronaveShitje";
import PronaByUserShitje from "./components/PronaByUser/PronaByUserShitje";
function App() {
  AOS.init();
  return (
    <Fragment>
      <div className="App">
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Hero />
                      <Prona />
                      <BestProperty />
                      <Wallpaper />
                      <About />
                      <Footer /> 
                    </>
                  }
                />

                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
                <Route path="/dokumentacion" element={<Rregullore />} />


                <Route path="/signup1d3@lh0m3" element={<Signup />} />

                <Route path="/signin" element={<Signin />} />
                <Route path="/listing/:listingid" element={<SingleProperty/>}/>
                <Route path="/allproperties" element={<AllProperties/>}/>
                <Route path="/get" element={<AllProperties/>}/>
                
                <Route element={<PrivateRoute />}>
                  <Route path="/admin" element={<AdminPanel/>} />
                  <Route path="/admin/allproperty" element={<PronaAdminPanel/>} />
                  <Route path="/admin/addproperty" element={<PronaEditor/>} />
                  <Route path="/admin/seeproperty" element={<ListaPronave/>} />
                  <Route path="/admin/seepropertysold" element={<ListaPronaveShitje/>} />
                  <Route path="/admin/seeproperty/:id" element={<PronaByUser/>} />
                  <Route path="/admin/seepropertysold/:id" element={<PronaByUserShitje/>} />
                  <Route path="/listing/update/:listingId" element={<PropertyEdit/>}/>
                  <Route path="/admin/documentation" element={<Documentation/>}/>
                  <Route path="/filter" element={<Filter/>} />
                  <Route path="/admin/user" element={<User/>} />
                  <Route path="/user/update/:id" element={<UpdateUsers/>} />
                  <Route path="/admin/signout" element={<Logout/>} />
                </Route>
                
              </Routes>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </div>
    </Fragment>
  );
}
export default App;
