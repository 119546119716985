import React from "react";
import { FaFacebook } from "react-icons/fa";
import "./Share.css";
const FacebookShareButton = ({ propertyUrl, quote }) => {
  return (
    <button className="fb-button">
      <div
        className="fb-share-button"
        data-href={propertyUrl}
        data-layout="button"
        data-size="small"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            propertyUrl
          )}&quote=${encodeURIComponent(quote)}`}
          className="fb-xfbml-parse-ignore"
        >
          <FaFacebook />
        </a>
      </div>
    </button>
  );
};

export default FacebookShareButton;
